import { apiPatch } from '../api.ts'
import { ICenter } from '@/api/auth/getMe.ts'

export interface IChangePassword {
    currentPassword: string;
    newPassword: string;

}

export const apiChangePassword = async (params: IChangePassword) => {
    return await apiPatch<ICenter>(`/auth/me/password`, params)
}
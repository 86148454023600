import { useEffect, useRef } from 'react'
import { useLoginStore } from '@/store/LoginStore.ts'
import useQueryGetUrgentNotification from '@/api/notification/getLiveEmergencyAlert.ts'
import { isNotEmpty } from '@/util/strings.ts'
import { isNotEmptyArray } from '@/util/array.ts'
import EmergencyAlertModal from '@/pages/notification/emergency/EmergencyAlertModal.tsx'
import { DISABLE_ALERTS_LIVE_FETCHING } from '@/constant/constant.ts'

const Loader = () => {
    const { accessToken } = useLoginStore()
    const { data: emergencyAlerts, refetch } = useQueryGetUrgentNotification(isNotEmpty(accessToken))
    const emergencyAlertDetailModalRef = useRef<any>()

    useEffect(() => {
        // todo 변동이 있을때가 아니라, 새로운 알림이 추가되었을 때 팝업을 띄우는 방식으로 변경해야 함. 최근 알림의 생성 시간을 활용하는 것도 방법.
        if (!DISABLE_ALERTS_LIVE_FETCHING && emergencyAlerts && isNotEmptyArray(emergencyAlerts?.emergencies)) {
            emergencyAlertDetailModalRef.current?.show()
        } else {
            emergencyAlertDetailModalRef.current?.close()
        }
    }, [emergencyAlerts])

    return (
        <>
            <EmergencyAlertModal ref={emergencyAlertDetailModalRef} refetchAlerts={refetch} />
        </>
    )
}

export default Loader

import React, { useMemo } from 'react'
import { equalString } from '@/util/strings.ts'
import StartBtn from '@/assets/ic_pagination_start.svg?react'
import PrevBtn from '@/assets/ic_pagination_prev.svg?react'
import NextBtn from '@/assets/ic_pagination_next.svg?react'
import EndBtn from '@/assets/ic_pagination_end.svg?react'

export interface IProps {
    className?: string
    total_page?: number
    current_page?: number
    page_size?: number
    fetchPage: (page: number) => void
}

interface IPageItem {
    type: 'start' | 'prev' | 'next' | 'normal' | 'end'
    page: number
    label?: string
    icon?: any
}

const Pagination: React.FC<IProps> = ({ className, total_page = 0, current_page = 0, page_size = 0, fetchPage }) => {

    const onClickPage = (page: number) => {
        fetchPage && fetchPage(page + 1)
    }

    const pageList: IPageItem[] = useMemo(() => {
        const result: IPageItem[] = []

        const MAX_PAGE = 5
        const totalPageCount = total_page

        const offsetPage = Math.floor((current_page - 1) / MAX_PAGE) * MAX_PAGE
        const lastPage =
            totalPageCount >= offsetPage + MAX_PAGE ? offsetPage + MAX_PAGE : offsetPage + (totalPageCount % MAX_PAGE)

        // add prev
        if (offsetPage > 0) {
            result.push({ type: 'start', page: 0, label: '<<', icon: <StartBtn className={'w-[20px]'} /> })
            result.push({ type: 'prev', page: offsetPage - 1, label: '<', icon: <PrevBtn className={'w-[20px]'} /> })
        }

        // normal pages
        for (let i = offsetPage; i < lastPage && i < totalPageCount; ++i) {
            result.push({ type: 'normal', page: i, label: (i + 1).toString() })
        }

        // add next
        if (totalPageCount > lastPage) {
            result.push({ type: 'next', page: lastPage, label: '>', icon: <NextBtn className={'w-[20px]'} /> })
            result.push({ type: 'end', page: total_page - 1, label: '>>', icon: <EndBtn className={'w-[20px]'} /> })
        }

        return result
    }, [total_page, current_page, page_size])

    return (
        <nav className={`${className} py-[12px] flex items-center justify-center`}>
            <ul className="inline-flex -space-x-px text-base gap-[10px]">
                {pageList?.map(item => {
                    if (equalString(item.type, 'normal'))
                        return (
                            <li
                                key={`${item.type}_${item.page}`}
                                onClick={() => onClickPage(item.page)}
                                className={`flex items-center justify-center cursor-pointer text-[16px] px-[5px] rounded-full min-w-[24px] font-medium ${
                                    item.page == (current_page - 1)
                                        ? 'bg-main_b text-white'
                                        : 'hover:text-main_b active:text-[#2966F4] active:opacity-40'
                                }`}>
                                <p>
                                    {item?.label}
                                </p>
                            </li>
                        )
                    else {
                        return <li
                            key={`${item.type}_${item.page}`}
                            onClick={() => onClickPage(item.page)}
                            className={`flex items-center justify-center cursor-pointer`}>
                            {item?.icon}
                        </li>
                    }
                })}
            </ul>
        </nav>
    )
}

export default Pagination

import React from 'react'

export interface IProps {
    className?: string
    children?: any
}

const HomeBenefitTextBox: React.FC<IProps> = ({ className, children }) => {
    return <div
        className={`${className} text-center text-[14px] text-white bg-black rounded-[10px] px-[20px] py-[12px] shadow-lg w-[200px]`}>{children}</div>
}

export default HomeBenefitTextBox
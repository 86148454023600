import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'

export interface ICenter {
    id: number;
    name: string;
    email: string;
    contact?: any;
    representerName?: any;
    representerContact?: any;
    representerEmail?: any;
    profileUrl?: any;
    latitude?: any;
    longitude?: any;
    createdAt: string;
    dashboardType: DashboardType;
}

export enum DashboardType {
    DEFAULT = 'default',
    CARE_MANAGER = 'caremanager'
}

export const apiGetMe = async () => {
    return await apiGet<ICenter>(`/auth/me`)
}

const useQueryGetMe = () => {
    return useQuery({
        queryKey: QueryCacheKeys.user.getMe(),
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const { data } = await apiGetMe()
            return data
        }
    })
}

export default useQueryGetMe


import React, { useRef } from 'react'
import BenefitDesktop1 from '@/assets/img_home_guide1_2.jpg'
import HomeBenefitTextBox from '@/pages/landing/Home/Benefit/HomeBenefitTextBox.tsx'
import MobileImage1 from '@/assets/img_home_benefit1_mobile.jpg'
import MobileImage2 from '@/assets/img_home_benefit2_mobile_2.jpg'
import Benefit2Desktop from '@/assets/img_home_benefit2_desktop_2.jpg'
import Benefit3Desktop from '@/assets/img_home_benefit3_desktop_2.jpg'
import Benefit2Mobile1 from '@/assets/img_home_benefit2_mobile1_2.jpg'
import Benefit2Mobile2 from '@/assets/img_home_benefit2_mobile2_2.png'
import Benefit3Mobile from '@/assets/img_home_benefit3_mobile.jpg'
import { useIsVisible } from '@/util/hook/useIsVisible.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

const HomeBenefit: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()

    const image1Ref = useRef<any>()
    const isVisibleImage1 = useIsVisible(image1Ref, true)

    const image2Ref = useRef<any>()
    const isVisibleImage2 = useIsVisible(image2Ref, true)

    const image3Ref = useRef<any>()
    const isVisibleImage3 = useIsVisible(image3Ref, true)

    return <div className={`${className} section_1200 whitespace-pre-wrap`}>
        {/* Benefit1 실시간 생체 데이터 기반 */}
        <div className={`transition-opacity ease-in duration-[1000ms] ${isVisibleImage1 ? 'opacity-100' : 'opacity-0'}`}
             ref={image1Ref}>
            <div className={'pt-[120px] pb-[10px] sm:hidden'}>
                <p className={'text-[48px] font-bold text-center'}>{t('home.benefit.benefit1.title')}</p>
                <img src={BenefitDesktop1} />
            </div>
            <div className={'pt-[46px] pb-[85px] hidden sm:flex flex-col items-center'}>
                <p className={'text-[24px] font-bold text-center'}>{t('home.benefit.benefit1.title')}</p>
                <div className={'mt-[60px] grid gap-[10px]'}>
                    <HomeBenefitTextBox>{t('home.benefit.benefit1.option1')}</HomeBenefitTextBox>
                    <HomeBenefitTextBox>{t('home.benefit.benefit1.option2')}</HomeBenefitTextBox>
                    <HomeBenefitTextBox>{t('home.benefit.benefit1.option3')}</HomeBenefitTextBox>
                </div>
                <img className={'mt-[30px]'} src={MobileImage1} />
                <HomeBenefitTextBox className={'mt-[36px]'}>{t('home.benefit.benefit1.option4')}</HomeBenefitTextBox>
                <img className={'mt-[30px]'} src={MobileImage2} />
            </div>
        </div>
        {/* Benefit2 긴급 알림 */}
        <div className={`transition-opacity ease-in duration-[1000ms] ${isVisibleImage2 ? 'opacity-100' : 'opacity-0'}`}
             ref={image2Ref}>
            <div className={'pt-[120px] pb-[10px] sm:hidden'}>
                <p className={'text-[48px] font-bold text-center'}>{t('home.benefit.benefit2.title')}</p>
                <img src={Benefit2Desktop} />
            </div>
            <div className={'pt-[46px] pb-[85px] hidden sm:flex flex-col items-center'}>
                <p className={'text-[24px] font-bold text-center'}>{t('home.benefit.benefit2.title')}</p>
                <div className={'mt-[60px] grid gap-[10px]'}>
                    <HomeBenefitTextBox>{t('home.benefit.benefit2.option1')}</HomeBenefitTextBox>
                    <HomeBenefitTextBox>{t('home.benefit.benefit2.option2')}</HomeBenefitTextBox>
                    <HomeBenefitTextBox>{t('home.benefit.benefit2.option3')}</HomeBenefitTextBox>
                </div>
                <img src={Benefit2Mobile1} />
                <div>
                    <HomeBenefitTextBox>{t('home.benefit.benefit2.option4')}</HomeBenefitTextBox>
                </div>
                <img src={Benefit2Mobile2} className={'mt-[30px]'} />
            </div>
        </div>

        {/* Benefit3 긴급 알림 */}
        <div className={`transition-opacity ease-in duration-[1000ms] ${isVisibleImage3 ? 'opacity-100' : 'opacity-0'}`}
             ref={image3Ref}>
            <div className={'pt-[120px] pb-[10px] sm:hidden'}>
                <p className={'text-[48px] font-bold text-center'}>{t('home.benefit.benefit3')}</p>
                <img src={Benefit3Desktop} />
            </div>
            <div className={'pt-[46px] pb-[85px] hidden sm:flex flex-col items-center'}>
                <p className={'text-[24px] font-bold text-center'}>{t('home.benefit.benefit3')}</p>
                <div className={'mt-[60px] grid gap-[10px]'}>
                    <HomeBenefitTextBox>{t('home.benefit.benefit3.option1')}</HomeBenefitTextBox>
                    <HomeBenefitTextBox>{t('home.benefit.benefit3.option2')}</HomeBenefitTextBox>
                    <HomeBenefitTextBox>{t('home.benefit.benefit3.option3')}</HomeBenefitTextBox>
                </div>
                <img src={Benefit3Mobile} />
            </div>
        </div>

    </div>
}

export default HomeBenefit
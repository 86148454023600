const user = {
    keyId: ['user'] as const,
    getMe: () => [...user.keyId, 'getMe'] as const,
    getMyPermission: () => [...user.keyId, 'getMyPermission'] as const,
    getUsers: () => [...user.keyId, 'getUsers'] as const,
    getUserDetail: (userID?: number) => [...user.keyId, 'getUserDetail', userID] as const,
    getUserRecentState: (userID?: number) => [...user.keyId, 'getUserRecentState', userID] as const,
    getUserStatAverage: (userID?: number) => [...user.keyId, 'getUserStatAverage', userID] as const,
    getUserCharts: (userID?: number) => [...user.keyId, 'getUserCharts', userID] as const,
    getUserCharts10Minutes: (userID?: number) => [...user.keyId, 'getUserCharts10Minutes', userID] as const
}

const notification = {
    keyId: ['notification'] as const,
    getEmergencyAlertLive: () => [...notification.keyId, 'getEmergencyAlertLive'] as const,
    getEmergencyAlertToday: () => [...notification.keyId, 'getEmergencyAlertToday'] as const,
    getAlertBioToday: () => [...notification.keyId, 'getAlertBioToday'] as const,
    getAlertBioLive: () => [...notification.keyId, 'getAlertBioLive'] as const,
    getAlertBioDetail: (id?: number) => [...notification.keyId, 'getAlertBioDetail', id] as const,
    getAlertEmergencyDetail: (id?: number) => [...notification.keyId, 'getAlertEmergencyDetail', id] as const,
    apiGetNotificationSos: (query?: string) => [...notification.keyId, 'apiGetNotificationSos', query] as const,
    apiGetNotificationBio: (query?: string) => [...notification.keyId, 'apiGetNotificationBio', query] as const,
    getUrgentNotification: () => [...notification.keyId, 'getUrgentNotification'] as const,
    getUrgentNotificationHistory: () => [...notification.keyId, 'getUrgentNotificationHistory'] as const
}

export const QueryCacheKeys = {
    user,
    notification
}

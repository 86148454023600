import { create } from 'zustand'
import { getCookie, removeCookie, setCookie } from '@/util/cookie.ts'
import { persist } from 'zustand/middleware'
import { QueryClient } from '@tanstack/react-query'

interface ILoginStore {
    accessToken: string
    login: (id: string, accessToken: string) => void
    logout: () => void
    isAutoLogin?: boolean
    isSaveID?: boolean
    savedID?: string
    setAutoLogin: (checked: boolean) => void
    setSaveID: (checked: boolean) => void
    saveID: (id: string) => void

}

export const KEY_ACCESS_TOKEN = 'access_token'
export const useLoginStore = create<ILoginStore>()(
    persist(
        (set, get) => ({
            isAutoLogin: false,
            isSaveID: false,
            accessToken: getCookie(KEY_ACCESS_TOKEN),
            login: async (id = '', accessToken = '') => {
                set(_ => ({ accessToken: accessToken }))

                if (get().isAutoLogin) {
                    setCookie(KEY_ACCESS_TOKEN, accessToken, { path: '/' })
                } else {
                    setCookie(KEY_ACCESS_TOKEN, accessToken, {
                        path: '/',
                        expires: new Date(Date.now() + 86400 * 1000)
                    })
                }

                if (get().isSaveID) {
                    set(_ => ({ savedID: id }))
                } else {
                    set(_ => ({ savedID: undefined }))
                }
            },
            logout: () => {
                set(_ => ({ accessToken: undefined }))
                removeCookie(KEY_ACCESS_TOKEN, { path: '/' })

                // React Query 캐시 초기화
                const queryClient = new QueryClient()
                queryClient.removeQueries()
            },
            setAutoLogin: (checked = false) => {
                set(_ => ({ isAutoLogin: checked }))
            },
            setSaveID: (checked = false) => {
                set(_ => ({ isSaveID: checked }))
            },
            saveID: (id = '') => {
                set(_ => ({ savedID: id }))
            }
        }),
        {
            name: 'login-store'
        }
    )
)
import React, { Suspense, useEffect, useMemo, useRef } from 'react'
import useQueryGetUserDetail from '@/api/auth/getUser.ts'
import useQueryGetUserRecentState from '@/api/user/getUserRecentState.ts'
import StableBadge from '@/pages/users/StableBadge.tsx'
import { useNavigate } from 'react-router-dom'
import StepChartContainer from '@/pages/users/StepChartContainer.tsx'
import HeartRateChartContainer from '@/pages/users/detail/HeartRateChartContainer.tsx'
import RespirationChartContainer from '@/pages/users/detail/RespirationChartContainer.tsx'
import UserDetailMap from '@/pages/users/UserDetailMap.tsx'
import { format } from 'date-fns'
import { calculateAge } from '@/util/person.ts'
import ContactBadge from '@/component/badge/ContactBadge.tsx'
import useQueryGetUserStatAverage from '@/api/user/getUserStatAverage.ts'
import AlertStatusBadge from '@/component/badge/AlertStatusBadge.tsx'
import { useTranslation } from 'react-i18next'
import TagP from '@/component/text/TagP.tsx'

export interface IProps {
    id?: number
    className?: string
    onRefresh: () => void
}

const UserDetail: React.FC<IProps> = ({ className, id = 0 }) => {
    const { t } = useTranslation()

    const { data: user } = useQueryGetUserDetail(id)
    const { data: recentState } = useQueryGetUserRecentState(id)
    const containerRef = useRef<any>(null)
    const navigate = useNavigate()
    const { data: averageStats } = useQueryGetUserStatAverage(id)


    const genderName = useMemo(() => {
        if (user?.gender == 0)
            return t('common.gender.male')
        if (user?.gender == 1)
            return t('common.gender.female')
        else
            return t('common.gender.none')
    }, [user?.gender])

    useEffect(() => {
        containerRef.current.scrollTo(0, 0)
    }, [id])

    const getTime = (minutes?: number) => {
        const pastMinutes = minutes || 0
        const hour = Math.floor(pastMinutes / 60)
        const minute = Math.floor(pastMinutes % 60)
        return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
    }

    return <>
        <div className={`${className} bg-sub_b p-[20px] h-full`} ref={containerRef}>
            {user && <>
                <div className={'w-full flex gap-x-[20px]'}>
                    <div className={'flex-1 grid grid-cols-2 gap-x-[20px] gap-y-[10px]'}>
                        <div className={'flex flex-col'}>
                            <p className={'text-left text-[14px] font-bold'}>{t('users.detail.info.title')}</p>
                            <div className={'flex-1 bg-white p-[20px] w-full flex flex-col gap-[10px] mt-[10px]'}>
                                <div className={'flex gap-[10px]'}>
                                    <AlertStatusBadge value={user?.alertStatus} />
                                    <p className={'font-bold'}>{user?.name}</p>
                                </div>
                                <p>{format(new Date(user?.createdAt), 'yyyy')}-{user?.id}</p>
                                <p className={''}>{genderName} / {format(new Date(user?.birthDate), 'yyyy-MM-dd')} ({t('users.detail.info.age', { count: calculateAge(user?.birthDate) })})</p>
                                <p>{t('users.detail.info.address')} : {user?.address ? user?.address : t('common.none')}</p>
                            </div>
                        </div>
                        <div className={'flex flex-col'}>
                            <p className={'text-left text-[14px] font-bold'}>{t('users.detail.time.title')}</p>
                            <div
                                className={'flex-1 bg-white p-[20px] w-full mt-[10px] text-[14px] text-center flex flex-col gap-[10px]'}>
                                <div
                                    className={'w-full grid grid-cols-3 gap-[10px] border border-solid border-transparent border-b border-b-gray2 pb-[10px]'}>
                                    <p>{t('users.detail.time.table.period.column')}</p>
                                    <p>{t('users.detail.time.table.powerOn.column')}</p>
                                    <p>{t('users.detail.time.table.wearOn.column')}</p>
                                </div>
                                <div className={'w-full grid grid-cols-3'}>
                                    <p>{t('users.detail.time.table.period.recent24hours')}</p>
                                    <p>{getTime(averageStats?.devicePowerOnMinute?.day)}</p>
                                    <p>{getTime(averageStats?.deviceWearMinute?.day)}</p>
                                </div>
                                <div className={'w-full grid grid-cols-3'}>
                                    <p>{t('users.detail.time.table.period.recent7days')}</p>
                                    <p>{getTime(averageStats?.devicePowerOnMinute?.week)}</p>
                                    <p>{getTime(averageStats?.deviceWearMinute?.week)}</p>
                                </div>
                                <div className={'w-full grid grid-cols-3'}>
                                    <p>{t('users.detail.time.table.period.recent30days')}</p>
                                    <p>{getTime(averageStats?.devicePowerOnMinute?.month)}</p>
                                    <p>{getTime(averageStats?.deviceWearMinute?.month)}</p>
                                </div>
                            </div>

                        </div>
                        <div className={'flex flex-col'}>
                            <p className={'text-left text-[14px] font-bold'}>{t('users.detail.contact.title')}</p>
                            <div className={'flex-1 bg-white p-[20px] w-full mt-[10px] flex flex-col gap-[14px]'}>
                                <div className={'flex gap-[20px]'}>
                                    <ContactBadge>{t('users.detail.contact.me')}</ContactBadge>
                                    <p>{user?.contact ? user?.contact : t('common.none')}</p>
                                </div>
                                <div className={'flex gap-[20px]'}>
                                    <ContactBadge>{t('users.detail.contact.protector')}</ContactBadge>
                                    <p>{user?.primaryContact ? user?.primaryContact : t('common.none')}</p>
                                </div>
                                <div className={'flex gap-[20px]'}>
                                    <ContactBadge>{t('users.detail.contact.friend')}</ContactBadge>
                                    <p>{user?.secondaryContact ? user?.secondaryContact : t('common.none')}</p>
                                </div>
                                <div className={'flex gap-[20px]'}>
                                    <ContactBadge>{t('users.detail.contact.admin')}</ContactBadge>
                                    <p>{user?.tertiaryContact ? user?.tertiaryContact : t('common.none')}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-col'}>
                            <p className={'text-left text-[14px] font-bold'}>{t('users.detail.memo.title')}</p>
                            <div className={'flex-1 bg-white p-[20px] w-full mt-[10px]'}>
                                {user?.inspectorMemo}
                            </div>
                        </div>
                    </div>
                    <div className={'w-[377px] flex flex-col gap-[10px]'}>
                        <div className={'flex-1 flex flex-col'}>
                            <div
                                className={'flex-1 flex justify-between items-center pl-[25px] pr-[6px] py-[11px] min-h-[48px] bg-white'}>
                                <p className={'text-left text-[14px] font-bold'}>{t('users.detail.location.title')}</p>
                                {user?.device &&
                                    <button className={'font-medium text-main_b px-[6px] py-[3px]'}
                                            onClick={() => navigate(`/user/${id}/map`)}>{t('users.detail.location.more')}
                                    </button>}
                            </div>
                            <Suspense fallback={null}>
                                <UserDetailMap className={'w-full h-full min-h-[213px]'} userID={user?.id} />
                            </Suspense>
                        </div>
                        <div className={'flex justify-between p-[12px] bg-[#353535]'}>
                            <p className={'text-white font-bold'}>{t('users.detail.location.device.status')}</p>
                            {user?.device && recentState?.summary ? (
                                <>
                                    {/* 모든 상태 값이 미사용 조건일 경우 */}
                                    {recentState.summary.hrate === null &&
                                    recentState.summary.hrateStatus === null &&
                                    recentState.summary.respiration === null &&
                                    recentState.summary.respStatus === null &&
                                    recentState.summary.isCharging === false &&
                                    recentState.summary.isOnline === false &&
                                    recentState.summary.step === 0 &&
                                    (recentState.summary.battery === null || recentState.summary.battery === undefined) ? (
                                        <p className={'text-[#ADFF45]'}>{t('users.detail.location.device.status.notUse')}</p>
                                    ) : (
                                        <p className={'text-[#ADFF45]'}>
                                            {recentState.summary.isOnline === false
                                                ? t('users.detail.location.device.status.turnOff')
                                                : recentState.summary.isCharging && recentState.summary.isOnline
                                                    ? t('users.detail.location.device.status.charging')
                                                    : `${recentState.summary.battery}%`}
                                        </p>
                                    )}
                                </>
                            ) : (
                                <p className={'text-[#ADFF45]'}>{t('users.detail.location.device.status.none')}</p> // 데이터가 없을 때
                            )}
                        </div>
                    </div>
                </div>

                {/* 생체 데이터 심박, 생체 데이터 호흡*/}
                <div className={'grid mt-[15px] gap-[15px]'}>
                    <div>
                        <div className={'flex justify-between p-[12px] bg-[#353535]'}>
                            <TagP className={'text-white font-bold'}>{t('users.detail.heartRate.title')}</TagP>
                            <p>
                                {user?.device && <span
                                    className={'text-[#ADFF45]'}>{t('users.detail.heartRate.current', { count: recentState?.summary?.hrate })}</span>}
                                {recentState?.summary?.hrateStatus && <StableBadge
                                    className={'ml-[20px]'}
                                    state={user?.device ? recentState?.summary?.hrateStatus : '미연결'} />}
                            </p>
                        </div>
                        <div className={'bg-white overflow-hidden'}>
                            <HeartRateChartContainer id={id} />
                        </div>
                    </div>
                    <div>
                        <div className={'flex justify-between p-[12px] bg-[#353535]'}>
                            <TagP className={'text-white font-bold'}>{t('users.detail.respiration.title')}</TagP>
                            <p>
                                {user?.device && <span
                                    className={'text-[#ADFF45]'}>{t('users.detail.respiration.current', { count: recentState?.summary?.respiration })}</span>}
                                {recentState?.summary?.respStatus && <StableBadge
                                    className={'ml-[20px]'}
                                    state={user?.device ? recentState?.summary?.respStatus : '미연결'} />}
                            </p>
                        </div>
                        <div className={'bg-white overflow-hidden'}>
                            <RespirationChartContainer id={id} />
                        </div>
                    </div>
                    <div>
                        <div className={'flex justify-between p-[12px] bg-[#353535]'}>
                            <TagP className={'text-white font-bold'}>{t('users.detail.step.title')}</TagP>
                            <p>
                                {user?.device && <span
                                    className={'text-[#ADFF45]'}>{t('users.detail.step.current', { count: recentState?.summary?.step || 0 })}</span>}
                            </p>
                        </div>
                        <div className={'bg-white overflow-hidden'}>
                            <StepChartContainer id={id} />
                        </div>
                    </div>

                </div>
            </>}
        </div>
    </>
}

export default UserDetail


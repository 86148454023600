import parse, { domToReact } from 'html-react-parser'

export function parseTagComponent(text: any) {
    const options = {
        replace: (domNode: any) => {
            if (domNode.name === 'span') {
                return (
                    <span className={domNode.attribs?.class}>
                        {domToReact(domNode.children, options)}
                    </span>
                )
            }
        }
    }

    return parse(text, options)
}
import React, { useState } from 'react'
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react'

interface IProps {
    className?: string
    panelClassName?: string
    text?: string
    render?: JSX.Element
    children?: any
}

const ARROW_HEIGHT = 0
const GAP = 4

const AltTooltip: React.FunctionComponent<IProps> = (props) => {
    const { className, children, render, text } = props
    const [isOpen, setIsOpen] = useState(false)
    const { x, y, strategy, refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [flip(), shift(), offset(ARROW_HEIGHT + GAP)],
        whileElementsMounted: autoUpdate
    })

    const hover = useHover(context)

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role, hover])

    return (
        <div className={`${className}`}>
            <div
                ref={refs?.setReference}
                {...getReferenceProps()}
                className={`${className} cursor-pointer`}
                onClick={(e: any) => {
                    setIsOpen(!isOpen)
                    e.stopPropagation()
                }}>
                {render}
            </div>
            {isOpen && (
                <div
                    ref={refs?.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content'
                    }}
                    {...getFloatingProps()}
                    className={'z-tooltip'}
                    onClick={e => {
                        setIsOpen(false)
                        e.stopPropagation()
                    }}>
                    <div className={`px-[4px] rounded-[5px] bg-sub_p border border-gray2 shadow-sm`}>
                        <div className={'text-body'}>{text}</div>
                        {children}
                    </div>
                </div>
            )}
        </div>)
}

export default AltTooltip
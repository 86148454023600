import React, {useRef} from 'react'

interface IProps {
    id: string
    className?: string
    label?: string
    disabled?: boolean
    value?: boolean
    onChanged?: (isChecked: boolean) => void
}

const CheckBox: React.FC<IProps> = ({id, className, label, value, disabled = false, onChanged}) => {
    const inputRef = useRef(null)

    return (
        <div className={`flex items-center gap-x-1 ${className}`}>
            <input
                id={id}
                type="checkbox"
                ref={inputRef}
                checked={value}
                className={'w-4 h-4 peer'}
                disabled={disabled}
                onChange={event => {
                    onChanged && onChanged(event.target.checked)
                }}
            />
            <label htmlFor={id} className={'text-black peer-disabled:text-gray3'}>
                {label}
            </label>
        </div>
    )
}

export default CheckBox

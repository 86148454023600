import React, { useEffect, useMemo, useState } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import SearchBar from '@/component/input/SearchBar.tsx'
import SearchUserItem from '@/pages/users/SearchUserItem.tsx'
import useQueryGetUsers from '@/api/user/getUsers.ts'
import useDebounce from '@/util/hook/useDebounce.tsx'
import UserDetail from '@/pages/users/UserDetail.tsx'
import { useSearchParams } from 'react-router-dom'
import { isEmptyArray, isNotEmptyArray } from '@/util/array.ts'
import Pagination from '@/component/pagination/Pagination.tsx'
import { useTranslation } from 'react-i18next'

type IProps = {}

const Users: React.FC<IProps> = ({}) => {
    const { t } = useTranslation()

    const [searchParams, setSearchParams] = useSearchParams()

    const [search, setSearch] = useState('')
    const [userId, setUserId] = useState(Number(searchParams.get('id')))
    const [page, setPage] = useState(0)
    const { data, refetch, isLoading } = useQueryGetUsers()
    const debouncedSearchText = useDebounce(search, 200)
    const searchedList = useMemo(() => {
        const filtered = data?.filter(item => item?.name?.includes(debouncedSearchText))
        if (userId <= 0 && filtered?.length || 0 > 0) {
            if (filtered?.at(0))
                setUserId(filtered?.at(0)!.id)
        }

        return filtered
    }, [data, debouncedSearchText])

    const renderList = useMemo(() => {
        const pageCount = searchedList?.length || 0

        if (page > pageCount) {
            setPage(pageCount)
            return
        }

        return searchedList?.slice(page * 10, (page + 1) * 10)
    }, [searchedList, page])

    const onRefresh = () => {
        setUserId(-1)
        refetch()
    }

    useEffect(() => {
        setSearchParams({ id: userId?.toString() })
    }, [userId])

    return <>
        <PageConfig showTopNav={true} showSideNav={true}>
            {!isLoading && !isEmptyArray(data) && <div className={'grid grid-cols-[340px_auto] h-full'}>
                {<div className={'bg-white p-[20px] overflow-y-auto'}>
                    <div className={'flex gap-[7px]'}>
                        <SearchBar inputParams={{ placeholder: t('users.list.search.placeholder') }}
                                   onChanged={text => setSearch(text)} />
                    </div>
                    {isEmptyArray(searchedList) &&
                        <div className={'text-body text-gray4 text-center mt-[80px]'}>
                            {t('users.list.empty.search')}
                        </div>}
                    {isNotEmptyArray(searchedList) && <div>
                        <div className={'mt-[20px] grid gap-[10px] overflow-y-auto'}>
                            {renderList?.map(item => <SearchUserItem {...item} key={item?.id}
                                                                     onClick={(id: number) => setUserId(id)} />)}
                        </div>
                        <Pagination
                            className={'mt-[40px]'}
                            page_size={10} current_page={page + 1} total_page={(searchedList?.length || 0) / 10}
                            fetchPage={pageNumber => {
                                setPage(pageNumber - 1)
                            }} />
                    </div>}

                </div>}
                <UserDetail className={'overflow-y-auto'} id={userId} onRefresh={onRefresh} />
            </div>}
            {!isLoading && isEmptyArray(data) &&
                <div className={'bg-sub_b h-full flex flex-col justify-center items-center'}>
                    <p className={'mt-[13px] text-body'}>{t('users.list.empty')}</p>
                </div>}
        </PageConfig>
    </>
}

export default Users
import React, { useState } from 'react'
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole
} from '@floating-ui/react'

export interface WMOption {
    id: string
    text: string
    value?: any
    onClick?: () => void
}

interface IProps {
    className?: string
    render?: JSX.Element
    items?: WMOption[]
}

const GAP = 5

const Menu: React.FC<IProps> = ({ className, render, items }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { x, y, strategy, refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [flip(), shift(), offset(GAP)],
        whileElementsMounted: autoUpdate
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

    return (
        <div className={`${className} `}>
            <div
                ref={refs?.setReference}
                {...getReferenceProps()}
                className={`${className} cursor-pointer`}
                onClick={(e: any) => {
                    setIsOpen(!isOpen)
                    e.stopPropagation()
                }}>
                {render}
            </div>
            {isOpen && (
                <div
                    ref={refs?.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content'
                    }}
                    {...getFloatingProps()}
                    className={'z-tooltip cursor-pointer'}>
                    <div
                        className={`bg-white border border-line02 shadow-sm w-[148px] border-main_b border-solid divide-y divide-solid divide-main_b`}>
                        {items?.map(item => (
                            <div
                                key={item.id}
                                onClick={e => {
                                    item?.onClick && item?.onClick()
                                    setIsOpen(false)
                                    e?.stopPropagation()
                                }}
                                className={'px-[12px] py-[15px] hover:bg-main_b hover:text-white'}>
                                <p className={'text-body1 text-font02 hover:text-font01 truncate text-center font-medium'}>{item.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Menu

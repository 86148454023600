import {apiPost} from '@/api/api.ts'

type IResLogin = {
    token: string;
}

export interface IReqLogin {
    id: string
    password: string
}

export const apiLogin = async (params: IReqLogin) => {
    return await apiPost<IResLogin>(`/auth/login`, params)
}

import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'
import { IResGetUserStat } from '@/api/user/getUserChart.ts'


export const apiGetUserChart10Minutes = async (userId: number) => {
    return await apiGet<IResGetUserStat>(createUrl(`/protectee/${userId}/today`, {}))
}

const useQueryGetUserChart10Minutes = (userId: number) => {
    return useQuery({
        queryKey: QueryCacheKeys.user.getUserCharts10Minutes(userId),
        queryFn: async () => {
            const { data } = await apiGetUserChart10Minutes(userId)
            return data
        }
    })
}

export default useQueryGetUserChart10Minutes


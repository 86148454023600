import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'


export const apiGetUserChart = async (userId: number) => {
    return await apiGet<IResGetUserStat>(createUrl(`/protectee/${userId}/stats`, {
        numSample: 10,
        interval: 'daily'
    }))
}

const useQueryGetUserChart = (userId: number) => {
    return useQuery({
        queryKey: QueryCacheKeys.user.getUserCharts(userId),
        queryFn: async () => {
            const { data } = await apiGetUserChart(userId)
            return data
        }
    })
}

export default useQueryGetUserChart


export interface IHeartRateChartItem {
    x: string;
    y: number;
    yMin?: any;
    yMax?: any;
}

export interface IRespirationChartItem {
    x: string;
    y: number;
    yMin?: any;
    yMax?: any;
}

export interface IStepChartItem {
    x: string;
    y?: any;
}

export interface IResGetUserStat {
    hrate: IHeartRateChartItem[];
    respiration: IRespirationChartItem[];
    step: IStepChartItem[];
}

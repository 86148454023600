import { apiGet } from '../api.ts'
import { IUser } from '@/api/user/getUsers.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'

export const apiGetUser = async (userID?: number) => {
    return await apiGet<IUser>(createUrl(`/protectee/${userID}`))
}

const useQueryGetUserDetail = (userID?: number) => {
    return useQuery({
        enabled: (userID || 0) > 0,
        queryKey: QueryCacheKeys.user.getUserDetail(userID),
        queryFn: async () => {
            const { data } = await apiGetUser(userID)
            return data
        }
    })
}

export default useQueryGetUserDetail

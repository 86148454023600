import React, { useImperativeHandle, useRef, useState } from 'react'
import BaseModal from '@/component/modal/BaseModal.tsx'
import Input from '@/component/input/Input.tsx'
import BorderButton from '@/component/button/BorderButton.tsx'
import SolidButton from '@/component/button/SolidButton.tsx'
import { apiChangePassword } from '@/api/auth/changePassword.ts'
import { toast } from 'react-toastify'
import { equalString, isNotEmpty } from '@/util/strings.ts'
import { useTranslation } from 'react-i18next'


export interface IProps {
    className?: string
}

const ChangePasswordModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        }
    }))

    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordRe, setNewPasswordRe] = useState('')

    const onClickSubmit = async () => {
        if (isNotEmpty(newPassword) && !equalString(newPassword, newPasswordRe)) {
            toast.error(t('changePasswordModal.submit.error.passwordNotSame'))
            return
        }


        const { code } = await apiChangePassword({ currentPassword: password, newPassword: newPassword })
        if (code == 200) {
            toast.success(t('changePasswordModal.submit.success'))
            modalRef.current?.close()
        } else {
            toast.error(t('changePasswordModal.submit.error.failToChangePassword'))
        }
    }

    return <BaseModal
        ref={modalRef} showCloseButton={false}
        width={'400'}>
        <div className={'flex items-center justify-center px-[30px] py-[40px]'}>
            <div className={'w-full grid gap-[10px] max-w-[263px] '}>
                <p className={'text-center font-bold'}>{t('changePasswordModal.title')}</p>
                <Input className={'mt-[24px]'} inputClassName={'w-full'}
                       placeholder={t('changePasswordModal.current.placeholder')}
                       inputType={'password'}
                       onChanged={text => setPassword(text)} />
                <Input inputClassName={'w-full'} placeholder={t('changePasswordModal.new.placeholder')}
                       inputType={'password'}
                       onChanged={text => setNewPassword(text)} />
                <Input inputClassName={'w-full'} placeholder={t('changePasswordModal.newRe.placeholder')}
                       inputType={'password'}
                       onChanged={text => setNewPasswordRe(text)} />
                <div className={'flex gap-[10px] mt-[20px]'}>
                    <BorderButton text={t('changePasswordModal.cancel')} className={'flex-1'}
                                  onClick={() => modalRef?.current?.close()} />
                    <SolidButton text={t('changePasswordModal.confirm')} className={'flex-1'} onClick={onClickSubmit} />
                </div>
            </div>
        </div>
        <div>

        </div>
    </BaseModal>
}

export default React.forwardRef(ChangePasswordModal)

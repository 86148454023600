import React, { useState } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import Input from '@/component/input/Input.tsx'
import { apiLogin } from '@/api/auth/login.ts'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLoginStore } from '@/store/LoginStore.ts'
import ClomentalLogo from '@/assets/ic_clomental_logo.svg?react'
import SolidButton from '@/component/button/SolidButton.tsx'
import CheckBox from '@/component/checkbox/CheckBox.tsx'
import { isEmpty } from '@/util/strings.ts'
import { useTranslation } from 'react-i18next'

type IProps = {}

const Login: React.FC<IProps> = ({}) => {
    const { t } = useTranslation()
    const { login, setAutoLogin, isAutoLogin, isSaveID, setSaveID, savedID } = useLoginStore()

    const [id, setId] = useState(savedID as string)
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const onClickLogin = async () => {
        const { data } = await apiLogin({ id: id, password: password })
        if (data) {
            login(id, data.token)
            toast.success(t('login.submit.success'))
            navigate('/')
        } else {
            toast.error(t('login.submit.fail'))
        }
    }

    return <>
        <PageConfig className={'mx-auto max-w-[300px] flex flex-col'}>
            <div className={'flex flex-col h-full justify-center items-center'}>
                <div>
                    <div className={'flex items-center justify-center'}>
                        <a href={'/'}>
                            <ClomentalLogo className={'w-[250px]'} />
                        </a>
                    </div>
                    <Input className={'mt-[40px]'} inputClassName={'w-full'} value={id} onChanged={text => setId(text)}
                           inputType={'text'}
                           placeholder={t('login.id.placeholder')} />
                    <Input className={'mt-[10px]'} inputClassName={'w-full'} value={password} inputType={'password'}
                           placeholder={t('login.password.placeholder')}
                           onPressEnter={onClickLogin}
                           onChanged={text => setPassword(text)} />

                    <SolidButton size={'medium'} text={t('login.confirm')} onClick={onClickLogin}
                                 disabled={isEmpty(id) || isEmpty(password)} className={'mt-[10px] w-full'} />
                    <div className={'flex justify-center mt-[10px] gap-[25px]'}>
                        <CheckBox id={'save_id'} label={t('login.saveId')} onChanged={isChecked => setSaveID(isChecked)}
                                  value={isSaveID} />
                        <CheckBox id={'auto_login'} label={t('login.autoLogin')}
                                  onChanged={isChecked => setAutoLogin(isChecked)}
                                  value={isAutoLogin} />
                    </div>
                    <div className={'flex flex-col justify-end mt-[20px] text-center'}>
                        <p className={'text-body'}>{t('login.customerCenter.desc')}</p>
                        <p className={'text-body'}>{t('login.customerCenter.desc.phone')}</p>
                    </div>
                </div>
            </div>
        </PageConfig>
    </>
}

export default Login
import React, { useMemo } from 'react'
import Case1Img from '@/assets/img_home_case_1.jpg'
import Case2Img from '@/assets/img_home_case_2.jpg'
import Arrow from '@/assets/ic_home_case_arrow.svg?react'
import Cooperation1 from '@/assets/home_case_cooperation1.png'
import Cooperation2 from '@/assets/home_case_cooperation2.png'
import Cooperation3 from '@/assets/home_case_cooperation3.png'
import Cooperation4 from '@/assets/home_case_cooperation4.png'
import Cooperation5 from '@/assets/home_case_cooperation5.png'
import Cooperation6 from '@/assets/home_case_cooperation6.png'
import Cooperation7 from '@/assets/home_case_cooperation7.png'
import CooperationBwave from '@/assets/home_case_cooperation_bwave.png'
import CooperationInhand from '@/assets/home_case_cooperation_inhand.png'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

interface IIntroductionCase {
    title: string
    desc: string
    image: any
    href?: string
}


const HomeIntroductionCase: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()

    const data: IIntroductionCase[] = useMemo(() => [
        {
            title: t('home.introductionCase.case1.title'),
            desc: t('home.introductionCase.case1.desc'),
            image: Case1Img,
            href: 'https://n.news.naver.com/article/008/0005049814'
        },
        {
            title: t('home.introductionCase.case2.title'),
            desc: t('home.introductionCase.case2.desc'),
            image: Case2Img,
            href: 'https://www.sportsseoul.com/news/read/1440417?ref=naver'
        }
    ], [t])

    return <div className={`${className} py-[130px] bg-gray1`}>
        <div className={'section_1200'}>
            <p className={'text-[48px] sm:text-[24px] font-bold text-center'}>{t('home.introductionCase.title')}</p>

            <div className={'grid grid-cols-2 sm:grid-cols-1 mt-[30px]'}>
                {data?.map(item => <>
                        <a target="_blank" rel="noopener noreferrer" href={item.href}
                           className={'flex flex-col items-center justify-center py-[90px] sm:py-[40px]'}>
                            <img src={item.image} />
                            <p className={'mt-[10px] text-[24px] font-medium text-center'}>{item.title}</p>
                            <p className={'text-[20px] font-medium text-center'}>{item.desc}</p>
                            <p className={'mt-[10px] text-[20px] text-main_b font-bold'}>
                                {t('home.introductionCase.more')}
                                <Arrow className={'inline-block'} />
                            </p>
                        </a>
                    </>
                )}
            </div>

            <div className={''}>
                <p className={'text-center text-[20px] font-medium text-[#828282] py-[32px]'}>{t('home.introductionCase.cooperate.title')}</p>
                <div className={'flex sm:flex-col gap-[78px] sm:gap-[50px] justify-center items-center'}>
                    <img src={Cooperation1} />
                    <img src={Cooperation2} />
                    <img src={Cooperation3} />
                </div>
                <div
                    className={'mt-[10px] sm:mt-[50px] flex sm:flex-col gap-[78px] sm:gap-[50px] justify-center items-center'}>
                    <img src={Cooperation4} />
                    <img src={Cooperation5} />
                    <img src={CooperationBwave} />
                </div>
                <div
                    className={'mt-[10px] sm:mt-[50px] flex sm:flex-col gap-[78px] sm:gap-[50px] justify-center items-center'}>
                    <img src={CooperationInhand} />
                    <img src={Cooperation6} />
                    <img src={Cooperation7} />
                </div>
            </div>
        </div>
    </div>
}

export default HomeIntroductionCase
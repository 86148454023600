import React from 'react'
import {NavLink} from 'react-router-dom'

export interface INavItem {
    id: string
    label: string
    href: string
    disabled?: boolean
}

export interface IProps extends INavItem {
    className?: string
}

const SideNavItem: React.FC<IProps> = ({className, label, href, disabled = false}) => {
    return (
        <div className={`${className}`}>
            {!disabled ?
                <NavLink
                    to={href}
                    className={({isActive}) =>
                        [
                            'block min-h-[60px] px-[20px] py-[20px] font-medium text-center',
                            isActive ? 'bg-main_b text-white ' : 'text-black'
                        ].join(' ')}>
                    {label}
                </NavLink> :
                <p className={'block min-h-[60px] px-[20px] py-[20px] font-medium text-center cursor-default text-[#989898]'}>{label}</p>
            }
        </div>
    )
}

export default SideNavItem

import React, { useEffect, useState } from 'react'

export interface ITableProps<T> {
    className?: string
    columns: ITableColumn<T>[]
    extractKey: (item: T) => string
    data: T[]
    onClickRow?: (item: T) => void
    header?: ITableHeader
    renderHeader?: () => React.ReactNode
    renderEmpty?: React.ReactNode
    onClick?: () => void
    enableOnClick?: boolean
    hideTable?: boolean
    hoverBorderClassName?: string
    backgroundColorClassName?: string
    itemRowClassName?: (item: T) => string
    itemCommonClassName?: (item: T) => string
}

export interface ITableColumn<T> {
    key: string
    header: () => React.ReactNode
    visibilityClassName?: string
    thClassName?: string
    cell: (item: T) => React.ReactNode
}

export interface ITableHeader {
    bgClassName?: string
    paddingHorizontal?: number
    borderClassName?: string
}

const Table: React.FC<ITableProps<any>> = ({
                                               data = [],
                                               columns,
                                               extractKey,
                                               onClickRow,
                                               className,
                                               renderHeader,
                                               renderEmpty,
                                               itemRowClassName,
                                               onClick,
                                               enableOnClick = false,
                                               hoverBorderClassName,
                                               itemCommonClassName,
                                               backgroundColorClassName = 'bg-white',
                                               hideTable = false
                                           }) => {
    const [tableColumns, setTableColumns] = useState<ITableColumn<any>[]>(columns)

    useEffect(() => {
        setTableColumns(columns)
    }, [columns])

    const onClickTable = () => {
        if (!enableOnClick) return

        onClick && onClick()
    }

    return (
        <div
            className={`${backgroundColorClassName} overflow-x-auto ${className} ${
                enableOnClick && onClick ? `${hoverBorderClassName} cursor-pointer` : ''
            }`}
            onClick={onClickTable}>
            {renderHeader && renderHeader()}
            <table className={`w-full table-auto table-collapse border-spacing-0 ${hideTable && 'hidden'}`}>
                <thead className={`overflow-hidden bg-gray-50 rounded-lg`}>
                <tr>
                    {tableColumns.map(({ key, header, visibilityClassName, thClassName }) => (
                        <th key={key}
                            className={`${visibilityClassName} ${thClassName} text-body.small px-4 py-3 flex-1`}>
                            {header()}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data?.map(item => (
                    <tr
                        key={extractKey(item)}
                        onClick={() => onClickRow && onClickRow(item)}
                        className={` ${
                            onClickRow && 'hover:bg-gray-50 cursor-pointer '
                        } border-b ${itemRowClassName && itemRowClassName(item)}`}>
                        {tableColumns.map(({ key, cell, visibilityClassName }) => (
                            <td key={key}
                                className={`${visibilityClassName} ${itemCommonClassName && itemCommonClassName(item)} px-4 py-2 text-body.medium`}>
                                {cell(item)}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            {Number(data?.length) === 0 && renderEmpty}
        </div>
    )
}

export default Table

import React, { useMemo } from 'react'
import { equalString } from '@/util/strings.ts'
import { useTranslation } from 'react-i18next'

interface IProps {
    className?: string;
    state?: string
}

const StableBadge: React.FunctionComponent<IProps> = ({ className, state }) => {
    const { t } = useTranslation()

    const badgeClassName = useMemo(() => {
        if (equalString(state, '안정'))
            return 'bg-main_b text-white'
        else if (equalString(state, '불안정'))
            return 'bg-sub_r text-white'
        else if (equalString(state, '미연결'))
            return 'bg-sub_g text-black'
        else
            return ''
    }, [state])

    const badgeName = useMemo(() => {
        if (equalString(state, '안정'))
            return t('users.stableBadge.stable')
        else if (equalString(state, '불안정'))
            return t('users.stableBadge.unstable')
        else if (equalString(state, '미연결'))
            return t('users.stableBadge.disconnected')
        else
            return state
    }, [state, t])

    return <>
        {state && <span
            className={`${className} px-[10px] text-[14px] py-[5px] rounded-[7px] text-center ${badgeClassName}`}>
            {badgeName}
        </span>}
    </>
}

export default StableBadge
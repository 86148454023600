import React from 'react'
import { format } from 'date-fns'
import { IMarkerInfo, MarketTYPE } from '@/pages/dashboard/DashboardMapDefault.tsx'
import { AlertStatus } from '@/api/user/getUsers.ts'
import { useTranslation } from 'react-i18next'

interface IProps {
    info?: IMarkerInfo
}

const MarkerWindow: React.FunctionComponent<IProps> = ({ info }) => {
    const { t } = useTranslation()

    const deviceStatusClassName = (status?: AlertStatus) => {
        switch (status) {
            case AlertStatus.EMERGENCY:
                return 'border-sub_r hover:bg-sub_r hover:text-white'
            case AlertStatus.BIO:
                return 'border-[#FF7D33] hover:bg-[#FF7D33] hover:text-white'
            default:
                return 'border-main_b hover:bg-main_b hover:text-white'
        }
    }
    return <>
        <div className="px-[12px] py-[10px] max-w-[259px]">
            <p className={'text-[12px] leading-[22px]'}>{info?.users?.map(user => <a key={`${user?.id}`}
                                                                                     href={`/user?id=${user?.id}`}><span
                className={`px-[4px] min-h-[21px] border border-solid rounded-[5px] cursor-pointer mr-[5px] whitespace-nowrap ${deviceStatusClassName(user?.alertStatus)}`}
            >{user?.name}</span></a>)}</p>
            {info?.locationName && <p className="font-bold text-[14px] mt-[5px]">{info?.locationName}</p>}
            <p className="text-[12px] font-medium mt-[5px]">{info?.address}</p>
            {info?.location?.timestamp && info?.type == MarketTYPE.SINGLE &&
                <p className="text-[12px] text-gray4 mt-[5px]">{t('dashboard.marker.window.recent', { datetime: format(info?.location?.timestamp || 0, 'yyyy-MM-dd HH:mm') })}</p>}
        </div>
    </>
}

export default MarkerWindow
import React, { useRef } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import useQueryGetMe from '@/api/auth/getMe.ts'
import SolidButton from '@/component/button/SolidButton.tsx'
import BorderButton from '@/component/button/BorderButton.tsx'
import { useNavigate } from 'react-router-dom'
import ChangePasswordModal from '@/pages/account/ChangePasswordModal.tsx'
import AdminThumbnail from '@/component/image/AdminThumbnail.tsx'
import { useTranslation } from 'react-i18next'

type IProps = {}

export type IEditAccount = {
    name?: string
    isNameEditing: boolean,
    contact: string,
    isContactEditing: boolean,
    representerName: string,
    isRepresenterNameEditing: boolean,
    representerContact: string,
    isRepresenterContactEditing: boolean,
    representerEmail: string,
    isRepresenterEmailEditing: boolean,
    file?: File
}

const Account: React.FC<IProps> = ({}) => {
    const { data: center } = useQueryGetMe()
    const navigate = useNavigate()
    const changePasswordModalRef = useRef<any>()
    const { t } = useTranslation()

    return <>
        <PageConfig showSideNav={false} showTopNav={true}>
            <div className={'section_1200 mt-[20px] min-w-[170px] max-w-[300px]'}>
                <h1 className={'text-heading1 text-center mt-[130px]'}>{t('account.title')}</h1>
                <div className={'grid mt-[50px]'}>
                    <div className={'flex justify-center'}>
                        <AdminThumbnail className={''} src={center?.profileUrl}
                                        imageClassName={'w-[100px] h-[100px]'} />
                    </div>
                    <div className={'grid  mt-[32px]'}>
                        <BorderButton size={'small'} text={t('account.changePassword')} className={'w-full'}
                                      onClick={() => changePasswordModalRef.current?.show()} />
                        <p className={'mt-[10px] text-[14px] text-center'}>{t('account.forgotPassword.desc')}</p>
                        <p className={'text-[14px] text-center'}>{t('account.forgotPassword.desc.phone')}</p>
                    </div>
                    <SolidButton text={t('account.confirm')} className={'mt-[33px] w-full mx-auto'}
                                 onClick={() => navigate(-1)} />
                </div>
            </div>
        </PageConfig>
        <ChangePasswordModal ref={changePasswordModalRef} />
    </>
}

export default Account
import React, { useEffect } from 'react'

export interface IProps {
}

const DevPage: React.FC<IProps> = ({}) => {
    useEffect(() => {
        throw new Error('make custom error for datadog')
    }, [])

    return <div>Dev1</div>
}

export default DevPage
import React from 'react'
import CloseIcon from '@/assets/ic_close.svg?react'

export interface IProps {
    className?: string
    onClick?: () => void
}

const CloseButton: React.FC<IProps> = ({ className, onClick }) => {
    return (
        <button
            className={`${className} flex items-center justify-center p-[8px] rounded-[5px] border border-main_b`}
            onClick={onClick}>
            <CloseIcon />
        </button>
    )
}

export default CloseButton
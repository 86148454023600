import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import '@/locales/i18n'

// async function enableMocking() {
//     const { worker } = await import('./mocks/browser')
//     return worker.start()
// }

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

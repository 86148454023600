import { useEffect, useState } from 'react'

export function useIsVisible(ref: any, once = false) {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
                if (once) {
                    if (entry.isIntersecting) {
                        setIntersecting(entry.isIntersecting)
                    }
                } else {
                    setIntersecting(entry.isIntersecting)
                }
            }
        )

        observer.observe(ref.current)
        return () => {
            if (!once) {
                observer.disconnect()
            }
        }
    }, [ref])

    return isIntersecting
}
import React from 'react'
import { parseTagComponent } from '@/util/parseTagComponent.tsx'

interface IProps {
    children?: any
    className?: string
}

const TagP: React.FC<IProps> = ({ className, children }) => {
    return (
        <p className={className}>
            {parseTagComponent(children)}
        </p>
    )
}

export default TagP

import React, { useMemo, useState } from 'react'
import useQueryGetMe from '@/api/auth/getMe.ts'
import Menu, { WMOption } from '@/component/menu/Menu.tsx'
import { useLoginStore } from '@/store/LoginStore.ts'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ClomentalLogo from '@/assets/ic_clomental_logo.svg?react'
import SolidButton from '@/component/button/SolidButton.tsx'
import Hamburger from '@/assets/ic_hambuger.svg?react'
import TopNavMobileButton from '@/component/layout/TopNav/TopNavMobileButton.tsx'
import DotDotDot from '@/assets/ic_dotdotdot.svg?react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@/assets/img_language.svg?react'
import TagP from '@/component/text/TagP.tsx'

type IProps = {
    className?: string;
    type?: TYPE_TopNav
}


export enum TYPE_TopNav {
    DEFAULT,
    GUIDE,
}

const TopNav: React.FunctionComponent<IProps> = ({ className, type = TYPE_TopNav.DEFAULT }) => {
    const { data: me } = useQueryGetMe()

    const { logout, accessToken } = useLoginStore()
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const menuItems: WMOption[] = useMemo(() => {
        const list: WMOption[] = []

        list.push({
            id: 'account',
            text: t('topNav.menu.account'),
            onClick: () => {
                navigate('/account')
            }
        })

        list.push({
            id: 'logout',
            text: t('topNav.menu.logout'),
            onClick: () => {
                logout()
                toast.success(t('topNav.menu.logout.success'))
                navigate('/login')
            }
        })

        return list
    }, [t])

    // 언어 변경하기
    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    const languageItems: WMOption[] = useMemo(() => {
        const list: WMOption[] = []

        list.push({
            id: 'ko',
            text: '한국어',
            onClick: () => {
                changeLanguage('ko')
            }
        })
        list.push({
            id: 'en',
            text: 'English',
            onClick: () => {
                changeLanguage('en')
            }
        })
        list.push({
            id: 'ja',
            text: '日本語',
            onClick: () => {
                changeLanguage('ja')
            }
        })

        return list
    }, [])

    return <>
        <div className={'relative'}>
            <div
                className={`${className} w-full min-h-[80px] md:min-h-[50px] bg-white flex items-center px-[34px] md:px-[15px] border-b border-solid border-gray2 md:py-[10.5px] gap-[41px]`}>
                <a href={'/'}>
                    <ClomentalLogo className={'w-[151px] md:w-[115px] md:h-[28px]'} />
                </a>
                <div className={'grow flex gap-[10px]'}>
                    {type == TYPE_TopNav.GUIDE &&
                        <button className={'text-body md:hidden'}
                                onClick={() => navigate('/guide')}>{t('topNav.manual')}</button>}
                </div>
                <div className={'flex items-center gap-[10px]'}>
                    {type == TYPE_TopNav.DEFAULT && <Menu
                        items={menuItems}
                        render={<div className={'flex gap-[10px]'}>
                            <div className={'text-[14px] py-[5px] px-[10px] bg-sub_b'}>
                                <TagP>{t('topNav.sayHello', { name: me?.name })}</TagP>
                            </div>
                            <DotDotDot />
                        </div>} />}
                    {type == TYPE_TopNav.GUIDE &&
                        <SolidButton text={t('topNav.guide.service')}
                                     className={'rounded-[10px] min-h-[43px] md:hidden min-w-[160px]'}
                                     size={'small'} onClick={() => {
                            if (accessToken) {
                                navigate('/dashboard')
                            } else {
                                navigate('/login')
                            }
                        }} />}
                    <Menu items={languageItems} render={<div className={'flex gap-[10px]'}>
                        <div
                            className={'text-[14px] select-none bg-gray-500 text-white rounded-full min-w-[40px] min-h-[40px] flex items-center justify-center'}>
                            <LanguageIcon className={'fill-white p-[9px]'} />
                        </div>
                    </div>}>
                    </Menu>
                    {type == TYPE_TopNav.GUIDE && <div className={'hidden md:block'}>
                        <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
                            <Hamburger />
                        </button>
                    </div>}
                </div>
            </div>
        </div>
        {type == TYPE_TopNav.GUIDE && showMobileMenu &&
            <div className={'hidden md:grid w-full absolute top-[50px] shadow z-modal'}>
                <TopNavMobileButton text={t('topNav.guide.service')} onClick={() => {
                    if (accessToken) {
                        navigate('/dashboard')
                    } else {
                        navigate('/login')
                    }
                }} />
                <TopNavMobileButton text={t('topNav.guide.manual')} onClick={() => {
                    navigate('/guide')
                }} />
            </div>}
    </>
}

export default TopNav
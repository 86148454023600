import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { IAlertBio } from '@/api/notification/getNotificationBio.ts'
import { DISABLE_ALERTS_LIVE_FETCHING } from '@/constant/constant.ts'

export const apiGetAlertBioLive = async () => {
    return await apiGet<IResAlertBioLive>(`/alert/bio/live`)
}

const useQueryGetAlertBioLive = (enabled = false) => {
    return useQuery({
        enabled: enabled,
        queryKey: QueryCacheKeys.notification.getAlertBioLive(),
        refetchInterval: !DISABLE_ALERTS_LIVE_FETCHING ? 1000 : false,
        queryFn: async () => {
            const { data } = await apiGetAlertBioLive()
            return data
        }
    })
}

export default useQueryGetAlertBioLive

export interface IResAlertBioLive {
    bios: IAlertBio[];
}
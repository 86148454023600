import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { IAlertBio } from '@/api/notification/getNotificationBio.ts'

export const apiGetAlertBioDetail = async (id?: number) => {
    return await apiGet<IAlertBio>(`/alert/bio/${id}`)
}

const useQueryGetAlertBioDetail = (id?: number) => {
    return useQuery({
        queryKey: QueryCacheKeys.notification.getAlertBioDetail(id),
        enabled: (id || -1) >= 0,
        refetchInterval: 1000,
        queryFn: async () => {
            const { data } = await apiGetAlertBioDetail(id)
            return data
        }
    })
}

export default useQueryGetAlertBioDetail


import { Navigate } from 'react-router-dom'
import { useLoginStore } from '@/store/LoginStore.ts'
import { isNotEmpty } from '@/util/strings.ts'

interface IProps {
}

const HomeRoute = ({}: IProps) => {
    const { accessToken } = useLoginStore()
    const authenticate = isNotEmpty(accessToken)
    return authenticate ? (
        <Navigate
            to={'/dashboard'}
        />
    ) : (
        <Navigate
            to={'/home'}
        />
    )
}

export default HomeRoute
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Dialog } from '@headlessui/react'
import CloseButton from '@/component/button/CloseButton.tsx'

// import CloseIcon from '@/public/svg/ic_close.svg'

export interface IProps {
    title?: string
    renderTitleRight?: React.ReactNode
    description?: string
    onCloseCallback?: () => void
    onOpenCallback?: () => void
    disableOutSideClickClose?: boolean
    children?: React.ReactNode
    width?: ModalWidthType
    showCloseButton?: boolean
}

export type ModalWidthType = '400' | '600' | '800' | '900' | '1200'

interface IModalStyle {
    widthClassName?: string
}

const styleMap = new Map<ModalWidthType, IModalStyle>()
styleMap.set('400', { widthClassName: 'max-w-[400px] w-full' })
styleMap.set('600', { widthClassName: 'max-w-[600px] w-full' })
styleMap.set('800', { widthClassName: 'max-w-[800px] w-full' })
styleMap.set('900', { widthClassName: 'max-w-[900px] w-full' })
styleMap.set('1200', { widthClassName: 'max-w-[1200px] w-full' })

const BaseModal = (
    {
        children,
        title,
        width = '600',
        renderTitleRight,
        onCloseCallback,
        onOpenCallback,
        disableOutSideClickClose,
        showCloseButton = true
    }: IProps,
    ref: any
) => {
    let [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            showModal()
        },
        close: () => {
            closeModal()
        }
    }))

    const closeModal = () => {
        setIsOpen(false)
        onCloseCallback && onCloseCallback()
    }

    const showModal = () => {
        setIsOpen(true)
    }

    useEffect(() => {
        if (isOpen) {
            onOpenCallback && onOpenCallback()
        }
    }, [isOpen])

    return (
        <Dialog
            open={isOpen}
            onClose={disableOutSideClickClose ? () => null : () => {
                setIsOpen(false)
                onCloseCallback && onCloseCallback()
            }}
            className={'relative z-modal overflow-hidden'}>
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className={'fixed inset-0 flex w-screen items-center justify-center'}>
                <Dialog.Panel className={`${styleMap.get(width)?.widthClassName} rounded-lg bg-white`}>
                    {(showCloseButton || title) &&
                        <div className={'flex items-center gap-x-2 justify-between min-h-[50px] px-[10px]'}>
                            <Dialog.Title className={'text-black text-heading1'}>{title}</Dialog.Title>
                            {renderTitleRight}
                            {showCloseButton && <button onClick={closeModal}>
                                <CloseButton />
                            </button>}
                        </div>}
                    {children}
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}

export default React.forwardRef(BaseModal)

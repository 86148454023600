import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { useCallback } from 'react'
import { equalString } from '@/util/strings.ts'

export const apiGetMyPermission = async () => {
    return await apiGet<IPermission[]>(`/auth/me/permission`)
}

const useQueryGetMyPermission = () => {
    const { data } = useQuery({
        queryKey: QueryCacheKeys.user.getMyPermission(),
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const { data } = await apiGetMyPermission()
            return data
        }
    })

    const hasPermission = useCallback((subject: PermissionSubject, action: PermissionAction) => {
        const allPermissions = data?.filter(item => equalString(item.subject, PermissionSubject.ALL))
        if (allPermissions?.find(it => equalString(it.action, PermissionAction.ALL) || equalString(it.action, action)))
            return true

        const specificPermissions = data?.filter(item => equalString(item.subject, subject))
        return !!specificPermissions?.find(it => equalString(it.action, PermissionAction.ALL) || equalString(it.action, action))
    }, [data])

    return { hasPermission }
}

export default useQueryGetMyPermission


export interface IPermission {
    action: string;
    subject: string;
}

export enum PermissionSubject {
    ALL = 'all',
    ALERT_BIO = 'alert_bio',
    ALERT_EMERGENCY = 'alert_emergency',
    PROTECTEE = 'protectee',
    CENTER = 'center'
}

export enum PermissionAction {
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    CREATE = 'create',
    ALL = 'manage',
}

//

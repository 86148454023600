import React, { Suspense, useEffect, useMemo, useState } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import CloseButton from '@/component/button/CloseButton.tsx'
import { Circle, Container as MapDiv, InfoWindow, Marker, NaverMap, useNavermaps } from 'react-naver-maps'
import useQueryGetUserDetail from '@/api/auth/getUser.ts'
import { IMarker, IMarkerInfo, MarketTYPE } from '@/pages/dashboard/DashboardMapDefault.tsx'
import useIsMount from '@/util/hook/useIsMount.ts'
import ReactDOMServer from 'react-dom/server'
import MarkerWindow from '@/pages/dashboard/MarkerWindow.tsx'
import HomeIcon from '@/assets/ic_map_house.svg?react'
import AlertStatusBadge from '@/component/badge/AlertStatusBadge.tsx'

export interface IProps {
}

const UserDetailMapPage: React.FC<IProps> = ({}) => {
    const params = useParams()
    const userID = Number(params?.id)
    const navigate = useNavigate()
    const naverMaps = useNavermaps()
    const [map, setMap] = useState<any>(null)
    const { data } = useQueryGetUserDetail(userID)
    const [infoWindow, setInfoWindow] = useState<any>(null)
    const [userInfo, setUserInfo] = useState<any>(null)
    const { isMount } = useIsMount()

    const openUserInfo = (info?: IMarker) => {
        if (!info || userInfo?.id == info?.id) {
            setUserInfo(undefined)
        } else {
            naverMaps.Service.reverseGeocode(
                {
                    coords: new naver.maps.LatLng(info?.location?.latitude || 0, info?.location?.longitude || 0),
                    orders: 'addr,roadaddr'
                },
                function(status, response) {
                    if (status !== naver.maps.Service.Status.OK) {
                        return alert('Something Wrong!')
                    }
                    const result = response?.v2?.address?.jibunAddress
                    const infoWithAddress: IMarkerInfo = {
                        ...info!,
                        // @ts-ignore
                        locationName: response?.v2?.results?.at(1)?.land?.addition0?.value || '',
                        address: result
                    }
                    infoWithAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithAddress} />)
                    setUserInfo(infoWithAddress)
                }
            )
        }
    }

    const marker = useMemo(() => {
        if (!data) return undefined
        return {
            id: Number(params?.id),
            deviceStatus: data?.device?.status,
            location: {
                latitude: data?.location?.latitude,
                longitude: data?.location?.longitude,
                accuracy: data?.location?.accuracy,
                timestamp: data?.location?.timestamp
            },
            type: MarketTYPE.SINGLE,
            users: [data]
        }
    }, [data])

    useEffect(() => {
        if (!map || !infoWindow) {
            return
        }

        if (userInfo) {
            infoWindow.open(map, new naver.maps.LatLng(userInfo?.location?.latitude, userInfo?.location?.longitude))
        } else {
            infoWindow.close()
        }
    }, [userInfo])

    useEffect(() => {
        if (isMount && marker?.location && data) {
            openUserInfo(marker)
        }
    }, [isMount, marker, data])


    return <PageConfig showTopNav={true} showSideNav={true}>
        <div className={'w-full h-full relative'}>
            <Suspense fallback={null}>
                {isMount && data && <MapDiv
                    key={userID}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}>
                    {isMount && data?.location && <NaverMap
                        ref={setMap}
                        defaultCenter={new naverMaps.LatLng(data?.location?.latitude, data?.location?.longitude)}
                        defaultZoom={15}>
                        {isMount && data?.addressLatitude && data?.addressLongitude && <Marker
                            icon={{
                                content: ReactDOMServer.renderToString(<HomeIcon />),
                                size: new naver.maps.Size(25, 25),
                                anchor: new naver.maps.Point(12.5, 12.5)
                            }}
                            defaultPosition={new naverMaps.LatLng(data?.addressLatitude ?? 0, data?.addressLongitude ?? 0)}
                        />}
                        {isMount && data?.location && <>
                            <Circle center={new naverMaps.LatLng(data?.location?.latitude, data?.location?.longitude)}
                                    radius={data?.location.accuracy}
                                    fillColor={'#ffffff99'} strokeColor={'#ffffffff'}
                            />
                            <Marker
                                icon={{
                                    content: ReactDOMServer.renderToString(<AlertStatusBadge
                                        size={25}
                                        className={'cursor-pointer'}
                                        value={data?.alertStatus} />),
                                    size: new naver.maps.Size(25, 25),
                                    anchor: new naver.maps.Point(12.5, 12.5)
                                }}
                                defaultPosition={new naverMaps.LatLng(data?.location?.latitude ?? 0, data?.location?.longitude ?? 0)}
                                onClick={() => openUserInfo(marker)}
                            />
                        </>}
                        <InfoWindow ref={setInfoWindow}
                                    content={userInfo?.render}
                                    anchorSize={new naver.maps.Size(0, 48)}
                                    borderColor={'#D9D9D9'} borderWidth={1} />
                    </NaverMap>}
                </MapDiv>}
            </Suspense>
            <CloseButton className={'absolute top-0 right-0 p-[20px] bg-white m-[20px]'} onClick={() => navigate(-1)} />
        </div>
    </PageConfig>
}

export default UserDetailMapPage
import React, { useEffect, useMemo } from 'react'
import { INavItem } from '@/component/layout/SideNav/SideNavItem.tsx'
import NotificationNavItem from '@/pages/notification/layout/NotificationNavItem.tsx'
import { useSearchParams } from 'react-router-dom'
import { isEmpty } from '@/util/strings.ts'
import { useTranslation } from 'react-i18next'

interface IProps {
    className?: string;
    children?: any

}

interface INavItemNotification extends INavItem {
    tab?: string
}

const NotificationLayout: React.FunctionComponent<IProps> = ({ className, children }) => {
    const { t } = useTranslation()

    const [searchParams, setSearchParams] = useSearchParams()
    const navItems = useMemo(() => {
        const items: INavItemNotification[] = []
        items.push({
            id: 'sos_alert',
            label: t('notification.layout.tab.sos'),
            href: `/notification?tab=sos`,
            tab: 'sos'
        })
        items.push({
            id: 'bio_alert',
            label: t('notification.layout.tab.bio'),
            href: `/notification?tab=bio`,
            tab: 'bio'
        })
        return items
    }, [t])


    useEffect(() => {
        if (isEmpty(searchParams.get('tab') || '')) {
            setSearchParams({ tab: 'sos' })
        }
    }, [])

    return <>
        <div>
            <div className={'flex px-[20px] mt-[20px] gap-[12px] border-b border-solid border-[#B8B8B8]'}>
                {navItems?.map(item => <NotificationNavItem {...item} key={item?.id} tab={item?.tab} />)}
            </div>
            <div className={`${className}`}>
                {children}
            </div>
        </div>
    </>
}

export default NotificationLayout
import React, { HTMLInputTypeAttribute, useState } from 'react'
import { useMaskito } from '@maskito/react'
import { MaskitoOptions } from '@maskito/core'
import PasswordHide from '@/assets/ic_password_input_closed.svg?react'
import PasswordShow from '@/assets/ic_password_input_opened.svg?react'
import { equalString } from '@/util/strings.ts'

export interface IInputParams {
    className?: string;
    inputClassName?: string;
    value?: string
    disabled?: boolean
    placeholder?: string
    renderRight?: React.ReactElement
    inputType?: HTMLInputTypeAttribute
    onChanged?: (text: string) => void
    autoFocus?: boolean
    caption?: string
    error?: string
    autoComplete?: string | undefined
    children?: any
    patternMask?: MaskitoOptions

    onPressEnter?(): void
}

const Input: React.FunctionComponent<IInputParams> = ({
                                                          className,
                                                          inputClassName,
                                                          value,
                                                          disabled,
                                                          inputType,
                                                          placeholder,
                                                          onPressEnter,
                                                          onChanged,
                                                          renderRight,
                                                          autoFocus,
                                                          caption,
                                                          error,
                                                          autoComplete,
                                                          patternMask,
                                                          children
                                                      }) => {

    const inputRef = useMaskito({ options: patternMask })
    const [isHidePassword, setIsHidePassword] = useState(true)

    const onKeyDownEnter = (e: any) => {
        if (e.key === 'Enter') {
            onPressEnter && onPressEnter()
        }
    }

    const renderPasswordToggleButton = () => {
        switch (isHidePassword) {
            case true:
                return (
                    <button onClick={() => setIsHidePassword(false)}>
                        <PasswordShow className={'w-[13px]'} />
                    </button>
                )
            case false:
                return (
                    <button onClick={() => setIsHidePassword(true)}>
                        <PasswordHide className={'w-[13px]'} />
                    </button>
                )
        }
    }

    const getInputType = () => {
        switch (inputType) {
            case 'password':
                if (isHidePassword) return 'password'
                else return 'text'
            default:
                return inputType
        }
    }

    return <>
        <div className={`flex flex-col gap-y-[4px] ${className} min-h-[40px]`}>
            <div
                className={`flex gap-x-[4px] items-center py-[10px] ${inputClassName} px-[12px] ${disabled ? 'bg-[#F5F5F5] text-gray3' : 'bg-[#F0F5FF]'} overflow-hidden`}>
                <input
                    ref={inputRef}
                    className={`border-none text-[16px] font-medium text-black bg-transparent flex-1`}
                    placeholder={placeholder}
                    disabled={disabled}
                    type={getInputType()}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    value={value}
                    onKeyDown={onKeyDownEnter}
                    onInput={e => {
                        const text = e.currentTarget.value
                        onChanged && onChanged(text)
                    }}
                />
                {equalString(inputType, 'password') && renderPasswordToggleButton()}
                {renderRight}
            </div>
            {!error && caption && <ul className={'list-disc list-inside'}>
                <li className={'text-caption1 text-gray3 mt-[3px]'}>{caption}</li>
            </ul>}
            {error && <ul className={'list-disc list-inside'}>
                <li className={'text-caption1 text-sub_r mt-[3px]'}>{error}</li>
            </ul>}
            {children}
        </div>
    </>
}

export default Input
import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { IPagingReq, IPagingRes } from '@/api/paging.ts'
import { createUrl } from '@/util/url.ts'
import { IPosition } from '@/api/user/getUsers.ts'

interface IReqGetNotificationBio extends IPagingReq {

}

export const apiGetNotificationBio = async (params: IReqGetNotificationBio, query?: string) => {
    return await apiGet<IPagingRes<IAlertBio[]>>(createUrl('/alert/bio', {
        query: query,
        pageNum: params?.pageNum,
        pageSize: params?.pageSize
    }))
}

const useQueryGetNotificationBio = (props: IReqGetNotificationBio, query?: string) => {
    return useQuery({
        queryKey: QueryCacheKeys.notification.apiGetNotificationBio(query),
        queryFn: async () => {
            const { data } = await apiGetNotificationBio(props, query)
            return data
        }
    })
}

export default useQueryGetNotificationBio


export interface IAlertBioProtectee {
    id: number;
    name: string;
    gender: string;
    birthDate: string;
    contact: string;
    profileUrl: string;
    memo: string;
}

export interface IAlertBioNameDetail {
    resp: number;
    hrate: number;
    respStatus: string;
    hrateStatus: string;
}

export interface IAlertBioAlert {
    name: string;
    nameDetail: IAlertBioNameDetail;
    managerName: string;
    confirm: BioAlertConfirmStatus;
    result?: any;
    content?: string;
    isMyTask?: boolean;
    createdAt: string;
    checkedAt?: string;
    taskCheckAt?: string;
    taskManagerAt?: string;
    location: IPosition;
}

export enum BioAlertConfirmStatus {
    WAIT = '미처리',
    ASSIGN = '담당자 배정',
    DONE = '처리'
}

export interface IAlertBio {
    id: number;
    protectee: IAlertBioProtectee;
    protector: IProtectorContact;
    center: ICenterContact;
    alert: IAlertBioAlert;
}

export interface IProtectorContact {
    relation?: string;
    contact?: string;
    secondaryRelation?: string;
    secondaryContact?: string;
    tertiaryRelation?: string;
    tertiaryContact?: string;
}

export interface ICenterContact {
    name: string;
    contact: string;
}


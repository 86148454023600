import React, { useMemo } from 'react'
import useQueryGetUserChart from '@/api/user/getUserChart.ts'
import useQueryGetUserDetail from '@/api/auth/getUser.ts'
import useQueryGetUserStatAverage from '@/api/user/getUserStatAverage.ts'
import AverageStat from '@/pages/users/AverageStat.tsx'
import useQueryGetUserChart10Minutes from '@/api/user/getUserChart10Minutes.ts'
import DailyChart from '@/pages/users/detail/DailyChart.tsx'
import useQueryGetUserRecentState from '@/api/user/getUserRecentState.ts'
import RealtimeChart from '@/pages/users/detail/RealtimeChart.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
    id: number
}

const HeartRateChartContainer: React.FC<IProps> = ({ className, id }) => {
    const { t } = useTranslation()

    const { data: detail } = useQueryGetUserDetail(id)
    const { data: dailyData } = useQueryGetUserChart(id || -1)
    const { data: tenMinuteData } = useQueryGetUserChart10Minutes(id || -1)
    const { data: averageStats } = useQueryGetUserStatAverage(id || -1)
    const { data: recent } = useQueryGetUserRecentState(id || -1)

    const dailySortedItems = useMemo(() => {
        const values: any[] = []

        dailyData?.hrate?.forEach(item => {
            values?.push({ x: item.x, y: item.y, yMin: item.yMin, yMax: item.yMax, range: [item.yMin, item.yMax] })
        })

        return values
    }, [dailyData])

    const tenMinuteSortedItems = useMemo(() => {
        const values: any[] = []

        tenMinuteData?.hrate?.forEach(item => {
            values?.push({ x: item.x, y: item.y, yMin: item.yMin, yMax: item.yMax, range: [item.yMin, item.yMax] })
        })

        return values
    }, [tenMinuteData])


    return <div className={`${className} py-[40px] px-[34px] flex gap-[3px]`}>
        <div className={'flex flex-col gap-[20px] justify-center'}>
            <AverageStat title={t('users.detail.heartRate.average.daily')}
                         desc={averageStats?.hrate?.day?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={'bpm'} />
            <AverageStat title={t('users.detail.heartRate.average.weekly')}
                         desc={averageStats?.hrate?.week?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={'bpm'} />
            <AverageStat title={t('users.detail.heartRate.average.monthly')}
                         desc={averageStats?.hrate?.month?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={'bpm'} />
            <AverageStat title={t('users.detail.heartRate.average.yearly')}
                         desc={averageStats?.hrate?.year?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={'bpm'} />
        </div>
        {detail?.device && dailySortedItems.length > 0 ?
            <>
                <DailyChart className={'flex flex-1'} data={dailySortedItems} min={recent?.hrateBound?.at(0)}
                            max={recent?.hrateBound?.at(1)} />
                <RealtimeChart className={'flex flex-1'} data={tenMinuteSortedItems} min={recent?.hrateBound?.at(0)}
                               max={recent?.hrateBound?.at(1)} />
            </> :
            <>
                <div className={'h-[300px] flex-1 flex items-center justify-center'}>
                    {detail?.device && dailySortedItems.length == 0 &&
                        <p className={'text-body'}>{t('users.detail.heartRate.empty')}</p>}
                    {!detail?.device &&
                        <p className={'text-body'}>{t('users.detail.heartRate.empty.connectDevice')}</p>}
                </div>
            </>}
    </div>
}

export default HeartRateChartContainer
import React from 'react'
import TopNav, { TYPE_TopNav } from './TopNav/TopNav.tsx'
import SideNav from '@/component/layout/SideNav/SideNav.tsx'

type IProps = {
    className?: string;
    showTopNav?: boolean
    typeTopNav?: TYPE_TopNav
    showSideNav?: boolean
    children?: any
}
/*
    탑 네비게이션 한번에 컨트롤 @showTabNav (상단 고정)
    사이드 네비게이션 @showSideNav (좌측 고정, 300px)

 */
const PageConfig: React.FunctionComponent<IProps> = ({ className, showTopNav, showSideNav, typeTopNav, children }) => (
    <div className={`flex flex-col h-screen relative ${className} overflow-hidden`}>
        {showTopNav && <TopNav className={''} type={typeTopNav} />}
        <main className={'flex-1 flex overflow-y-auto'}>
            {showSideNav && <SideNav className={'overflow-y-auto'} />}
            <div className={'flex-1 overflow-y-auto'}>
                {children}
            </div>
        </main>
    </div>
)

export default PageConfig
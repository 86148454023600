import React from 'react'

export interface IProps {
    className?: string
    children?: any
}

const ContactBadge: React.FC<IProps> = ({ className, children }) => {
    return <div
        className={`${className} bg-main_b text-white rounded-[5px] min-w-[40px] px-[4px] text-center text-[12px] flex items-center justify-center`}>
        {children}
    </div>
}

export default ContactBadge
import React, { useCallback, useState } from 'react'
import useInterval from '@/util/hook/useInterval.tsx'
import { IAlertBio } from '@/api/notification/getNotificationBio.ts'
import { equalString } from '@/util/strings.ts'
import useQueryGetMyPermission, { PermissionAction, PermissionSubject } from '@/api/auth/getPermission.ts'
import AltTooltip from '@/component/tooltip/AltTooltip.tsx'
import TruncatedText from '@/component/tooltip/TruncatedText.tsx'
import { calculateAge } from '@/util/person.ts'
import LocationIcon from '@/assets/ic_alert_location.svg?react'
import { useTranslation } from 'react-i18next'

interface IProps extends IAlertBio {
    className?: string;
    onRefreshList: () => void
    onClickMarker: (id: number) => void
    onClickItem: (id: number) => void
}

const UrgentNotification: React.FunctionComponent<IProps> = ({
                                                                 id,
                                                                 onClickItem,
                                                                 className,
                                                                 alert,
                                                                 protectee,
                                                                 onClickMarker
                                                             }) => {

    const { t } = useTranslation()
    const getStableName = useCallback((value: string) => {
        if (equalString(value, '정상'))
            return t('dashboard.notification.stable')
        else
            return t('dashboard.notification.unstable')
    }, [t])

    const getGenderName = useCallback((value: string) => {
        if (equalString(value, '남'))
            return t('dashboard.notification.gender.male')
        else
            return t('dashboard.notification.gender.female')
    }, [t])

    const [pastTime, setPastTime] = useState('00:00:00')
    const { hasPermission } = useQueryGetMyPermission()

    useInterval(() => {
        const pastSeconds = Math.floor((Date.now() - new Date(alert?.createdAt).getTime()) / 1000)
        const hour = Math.floor(pastSeconds / 3600)
        const minute = Math.floor(pastSeconds % 3600 / 60)
        const second = Math.floor(pastSeconds % 60)
        setPastTime(`${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`)
    }, 1000)

    return <>
        <div
            className={`${className} relative bg-white min-h-[92px] min-w-[360px] p-[10px] overflow-hidden rounded-[10px] border border-solid border-sub_r flex`}>
            <div className={'overflow-hidden max-w-[110px] w-full'}>
                <AltTooltip
                    render={<TruncatedText textClassName={'text-[14px] font-bold'}
                                           text={protectee?.name} />} />
                <p className={'text-body'}>{t('dashboard.notification.age', { count: calculateAge(protectee?.birthDate) || 0 })} / {getGenderName(protectee?.gender)}</p>
                <p className={'text-body'}>{protectee?.contact}</p>
            </div>
            <div className={'w-[130px] flex flex-col items-start gap-[5px]'}>
                <div className={'min-h-[21px] bg-sub_r py-[2px] px-[8px] rounded-[10px] inline-block'}>
                    <p className={'text-[12px] leading-[17px] text-right font-medium text-white'}>{t('dashboard.notification.pastTime', { time: pastTime })}</p>
                </div>
                {alert?.nameDetail?.hrate &&
                    <p className={`text-body ${!equalString(alert?.nameDetail?.hrateStatus, '정상') && 'text-sub_r'}`}>{t('dashboard.notification.heartRate')} / {alert?.nameDetail?.hrate} / {getStableName(alert?.nameDetail?.hrateStatus)}</p>}
                {alert?.nameDetail?.resp &&
                    <p className={`text-body ${!equalString(alert?.nameDetail?.respStatus, '정상') && 'text-sub_r'}`}>{t('dashboard.notification.respiration')}
                        / {alert?.nameDetail?.resp} / {getStableName(alert?.nameDetail?.respStatus)}</p>}
            </div>
            <div className={'w-[100px] grid gap-[7px] items-start'}>
                <button
                    className={'bg-white min-h-[30px] text-body font-medium border border-solid w-full py-[5px] border-black'}
                    onClick={() => onClickMarker(protectee?.id)}>
                    <div className={'flex justify-center items-center gap-[2px]'}>
                        {t('dashboard.notification.location')}
                        <LocationIcon />
                    </div>

                </button>
                {hasPermission(PermissionSubject.ALERT_BIO, PermissionAction.UPDATE) &&
                    <button className={'bg-black min-h-[30px] text-white font-medium text-body'}
                            onClick={() => onClickItem && onClickItem(id)}>{t('dashboard.notification.confirm')}</button>}
            </div>
        </div>
    </>
}

export default UrgentNotification
import React from 'react'

export interface IProps {
    className?: string
    title?: string
    desc?: string
    postfix?: string
}

const AverageStat: React.FC<IProps> = ({ className, title, desc, postfix }) => {
    return <div className={`${className} flex justify-between min-w-[151px] rounded-[10px] p-[10px] bg-sub_b`}>
        <p>{title}</p>
        <p>{desc}{postfix && desc ? `${postfix}` : ''}</p>
    </div>
}

export default AverageStat
import React from 'react'
import useWindowSize from '@/util/hook/useWindowSize.ts'

export interface IProps {
    className?: string
    children?: any
}

const HomeMajorFeatureItem: React.FC<IProps> = ({ className, children }) => {
    const { isMd } = useWindowSize()
    return <div
        className={`${className} p-[50px] sm:p-[25px] bg-white min-h-[505px] sm:min-h-[244px] ${isMd ? '' : 'mx-[25px] rounded-[10px]'}`}
        style={{ marginInline: '' }}>{children}</div>
}

export default HomeMajorFeatureItem
import React from 'react'
import SpinnerIcon from '@/assets/ic_loading.svg?react'

export interface IProps {
    className?: string
    text?: string
    size?: SolidButtonSIZE
    isLoading?: boolean
    onClick?: () => void
    disabled?: boolean
}

export type SolidButtonSIZE = 'small' | 'medium' | 'large'

const styles = new Map<SolidButtonSIZE, string>()
styles.set('large', 'min-h-[60px] text-[16px] px-[20px]')
styles.set('medium', 'min-h-[50px] text-[16px] px-[20px]')
styles.set('small', 'min-h-[40px] text-[14px] py-[8px] px-[20px]')

const loadingStyles = new Map<SolidButtonSIZE, string>()
loadingStyles.set('large', 'w-[22px]')
loadingStyles.set('medium', 'w-[24px]')
loadingStyles.set('small', 'w-[18px]')

const SolidButton: React.FC<IProps> = ({
                                           className,
                                           text,
                                           disabled = false,
                                           size = 'medium',
                                           isLoading = false,
                                           onClick
                                       }) => {
    return (
        <button
            className={`${className} ${styles.get(size)} ${disabled ? 'bg-gray2' : 'bg-main_b'} text-white flex items-center gap-[6px] justify-center whitespace-nowrap truncate`}
            onClick={onClick}
        >
            {text}{isLoading && <SpinnerIcon className={`animate-spin ${loadingStyles.get(size)}`} />}
        </button>
    )
}

export default SolidButton
import React, { useCallback, useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import useQueryGetUserChart from '@/api/user/getUserChart.ts'
import { format } from 'date-fns'
import useQueryGetUserDetail from '@/api/auth/getUser.ts'
import AverageStat from '@/pages/users/AverageStat.tsx'
import useQueryGetUserStatAverage from '@/api/user/getUserStatAverage.ts'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
    id: number
}

const StepChartContainer: React.FC<IProps> = ({ className, id }) => {
    const { t } = useTranslation()

    const { data: detail } = useQueryGetUserDetail(id)
    const { data: averageStats } = useQueryGetUserStatAverage(id || -1)

    const { data } = useQueryGetUserChart(id || -1)
    const sortedItems = useMemo(() => {
        const values: any[] = []

        data?.step?.forEach(item => {
            values?.push({ x: item.x, y: item.y || 0 })
        })

        return values
    }, [data])

    const CustomTooltip = ({ active, payload }: any) => {
        const { t } = useTranslation()

        if (active && payload && payload.length) {
            const x = payload[0].payload.x
            const y = payload[0].payload.y

            return (
                <div
                    className={`min-w-[83px] p-[10px] bg-white bg-opacity-100 rounded-lg shadow border border-solid border-main_b`}>
                    <p className={'text-[12px] text-black text-center'}>
                        {format(x, t('common.date.format.monthDay'))}
                    </p>
                    <p className={`text-[16px] text-center font-bold text-main_b`}>{Math.floor(y).toFixed(0)} {t('common.step')}</p>
                </div>
            )
        }
        return null
    }

    const formatChartXAxis = useCallback((x: any) => {
        return format(x, t('common.date.format.monthDay'))
    }, [t])

    return <div className={`${className} py-[40px] px-[34px] flex gap-[63px]`}>
        <div className={'flex flex-col gap-[20px] justify-center'}>
            <AverageStat title={t('users.detail.step.average.daily')}
                         desc={averageStats?.step?.day?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={t('common.step')} />
            <AverageStat title={t('users.detail.step.average.weekly')}
                         desc={averageStats?.step?.week?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={t('common.step')} />
            <AverageStat title={t('users.detail.step.average.monthly')}
                         desc={averageStats?.step?.month?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={t('common.step')} />
            <AverageStat title={t('users.detail.step.average.yearly')}
                         desc={averageStats?.step?.year?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                         postfix={t('common.step')} />
        </div>
        {detail?.device && sortedItems.length > 0 ?
            <>
                <div className={'flex flex-1'}>
                    <ResponsiveContainer width={'99%'} height={300}>
                        <BarChart data={sortedItems} margin={{ top: 0, left: 0, right: 8, bottom: 0 }}>
                            <CartesianGrid />
                            <XAxis dataKey="x" tickFormatter={formatChartXAxis} />
                            <Tooltip content={<CustomTooltip />} cursor={true} />
                            <Bar dataKey="y" fill="#2966F4" barSize={22} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </> : <>
                <div className={'h-[300px] w-full flex items-center justify-center'}>
                    {detail?.device && sortedItems.length == 0 &&
                        <p className={'text-body'}>{t('users.detail.step.empty')}</p>}
                    {!detail?.device &&
                        <p className={'text-body'}>{t('users.detail.step.empty.connectDevice')}</p>}
                </div>
            </>}
    </div>
}

export default StepChartContainer
import React, { useMemo } from 'react'
import { IUser } from '@/api/user/getUsers.ts'
import { calculateAge } from '@/util/person.ts'
import TruncatedText from '@/component/tooltip/TruncatedText.tsx'
import AlertStatusBadge from '@/component/badge/AlertStatusBadge.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps extends IUser {
    className?: string
    onClick: (userId: number) => void
}

const SearchUserItem: React.FC<IProps> = ({ className, id, alertStatus, name, birthDate, gender, onClick }) => {
    const { t } = useTranslation()

    const genderName = useMemo(() => {
        if (gender == 0)
            return t('users.list.item.gender.male')
        if (gender == 1)
            return t('users.list.item.gender.female')
        else
            return t('users.list.item.gender.none')
    }, [gender, t])

    return <>
        <div
            className={`${className} flex gap-[10px] justify-between items-center px-[20px] py-[10px] overflow-hidden rounded-[10px] bg-sub_b hover:bg-blue-100 hover:cursor-pointer active:bg-blue-200 `}
            onClick={() => onClick(id)}>
            <AlertStatusBadge value={alertStatus} className={'w-[15px] h-[15px]'} />
            <TruncatedText className={'flex-1 text-center'} text={name} />
            <p>{t('users.list.item.age', { count: calculateAge(birthDate) })} / {genderName}</p>
        </div>
    </>
}

export default SearchUserItem
import React, { useRef } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import { TYPE_TopNav } from '@/component/layout/TopNav/TopNav.tsx'
import SolidButton from '@/component/button/SolidButton.tsx'
import Footer from '@/component/layout/Footer/Footer.tsx'
import SalesModal from '@/pages/landing/Home/SalesModal.tsx'
import { useLoginStore } from '@/store/LoginStore.ts'
import { useNavigate } from 'react-router-dom'
import BorderButton from '@/component/button/BorderButton.tsx'
import LoginImage from '@/assets/img_guide_login1.jpg'
import DashboardImage1 from '@/assets/img_guide_dashboard1_2.jpg'
import DashboardImage2 from '@/assets/img_guide_dashboard2_2.jpg'
import DashboardImage3 from '@/assets/img_guide_dashboard2_2mobile.jpg'
import GuideTitle from '@/pages/landing/Guide/GuideTitle.tsx'
import ManageUserImage1 from '@/assets/img_guide_manage1.jpg'
import ManageUserImage2 from '@/assets/img_guide_manage2.jpg'
import { useTranslation } from 'react-i18next'


interface IProps {
}

const GuidePage: React.FunctionComponent<IProps> = ({}) => {
    const { t } = useTranslation()
    const salesModalRef = useRef<any>(null)
    const navigate = useNavigate()
    const { accessToken } = useLoginStore()

    const onClickOpenSalesModal = () => {
        salesModalRef.current?.show()
    }

    const onClickGoDashboard = () => {
        if (accessToken) {
            navigate('/dashboard')
        } else {
            navigate('/login')
        }
    }

    return <>
        <PageConfig showTopNav={true} typeTopNav={TYPE_TopNav.GUIDE}>
            <div className={'whitespace-pre-wrap'}>
                {/* 로그인 */}
                <div className={'section_1200 pt-[110px] md:pt-[20px] pb-[27px] pl-[142px] pr-[106px] md:px-[24px]'}>
                    <p className={'text-[36px] md:text-[25px] font-bold'}>{t('guide.login.title')}</p>
                    <div className={'grid md:grid-cols-1 grid-cols-[355px_auto] gap-[33px] mt-[16px] w-full'}>
                        <div>
                            <p className={'text-[14px]'}>
                                {t('guide.login.desc')}
                            </p>
                            <BorderButton className={'mt-4 rounded-[10px]'} text={t('guide.login.sales')}
                                          onClick={onClickOpenSalesModal} />
                        </div>
                        <div>
                            <img src={LoginImage} />
                        </div>
                    </div>
                </div>

                {/* 대시보드 */}
                <div
                    className={'section_1200 pt-[110px] pb-[27px] pl-[142px] pr-[106px] md:px-[24px]'}>
                    <p className={'text-[36px] md:text-[25px] font-bold'}>{t('guide.dashboard.title')}</p>
                    <div
                        className={'grid md:grid-cols-1 grid-cols-[355px_auto] gap-[33px] mt-[16px] w-full'}>
                        <div className={'text-[14px]'}>
                            <p className={''}>
                                {t('guide.dashboard.desc')}
                            </p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.dashboard.01.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.dashboard.01.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.dashboard.02.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.dashboard.02.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.dashboard.03.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.dashboard.03.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.dashboard.04.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.dashboard.04.desc')}</p>
                        </div>
                        <div className={'flex flex-col items-end gap-[9px]'}>
                            <img src={DashboardImage1} />
                            <img src={DashboardImage2} className={'block sm:hidden'} />
                            <img src={DashboardImage3} className={'hidden sm:block w-full'} />
                        </div>
                    </div>
                </div>

                {/* 회원관리 */}
                <div className={'section_1200 pt-[110px] pb-[27px] pl-[142px] pr-[106px] md:px-[24px]'}>
                    <p className={'text-[36px] md:text-[25px] font-bold'}>{t('guide.user.title')}</p>
                    <div className={'grid md:grid-cols-1 grid-cols-[355px_auto] gap-[33px] mt-[16px] w-full'}>
                        <div className={'text-[14px]'}>
                            <p>{t('guide.user.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.user.01.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.user.01.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.user.02.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.user.02.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.user.03.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.user.03.desc')}</p>
                            <GuideTitle className={'mt-[17px]'}>{t('guide.user.04.title')}</GuideTitle>
                            <p className={'mt-[5px]'}>{t('guide.user.04.desc')}</p>
                        </div>
                        <div className={'flex flex-col items-end gap-[33px]'}>
                            <img src={ManageUserImage1} />
                            <img src={ManageUserImage2} />
                        </div>
                    </div>
                </div>


                {/* 회원 관리 시작하기 */}
                <div className={'py-[183px] md:py-[65px] px-[44px]'}>
                    <div className={'section_1200'}>
                        <p className={'text-[48px] md:text-[25px] font-bold text-center'}>
                            {t('guide.start.title')}
                        </p>
                        <div className={'flex md:flex-col gap-[10px] justify-center mt-[23px]'}>
                            <SolidButton className={'rounded-[10px] min-h-[43px]'} text={t('guide.start.service')}
                                         onClick={onClickGoDashboard} />
                            <button className={'bg-white rounded-[10px] px-[20px] py-[10px] min-h-[43px] text-main_b'}
                                    onClick={onClickOpenSalesModal}>{t('guide.start.sales')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </PageConfig>
        <SalesModal ref={salesModalRef} />
    </>
}

export default GuidePage
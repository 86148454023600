import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'
import { MINUTE } from '@/constant/time.ts'

interface IResGetUsers {
}

export const apiGetUsers = async ({}: IResGetUsers = {}) => {
    return await apiGet<IUser[]>(createUrl(`/protectee`, {}))
}

const useQueryGetUsers = (params?: IResGetUsers) => {
    return useQuery({
        queryKey: QueryCacheKeys.user.getUsers(),
        refetchInterval: 1 * MINUTE,
        queryFn: async () => {
            const { data } = await apiGetUsers({ ...params })
            return data
        }
    })
}

export default useQueryGetUsers

export interface IUser {
    id: number;
    centerId: number;
    name: string;
    gender: number;
    birthDate: string;
    alertStatus?: AlertStatus;
    primaryRelation: string;
    primaryContact: string;
    secondaryRelation: string;
    tertiaryRelation: string;
    secondaryContact: string;
    tertiaryContact: string;
    contact?: string;
    inspectorMemo: string;
    createdAt: string;
    location?: IPosition;
    device: IDevice;
    profileUrl?: string
    address?: string
    addressLatitude?: number
    addressLongitude?: number
}

export interface IPosition {
    latitude: number;
    longitude: number;
    timestamp: string;
    address: string;
    accuracy: number;
}

export interface IDevice {
    id: number;
    device_serial: string;
    name?: any;
    last_update: string;
    status?: DeviceStatus;
    battery: number;
}

export enum DeviceStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
    CHARGING = 'charging',
    EMERGENCY = 'emergency'
}

export enum AlertStatus {
    NONE = 'none',
    EMERGENCY = 'emergency',
    BIO = 'bio'
}
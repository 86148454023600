import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Circle, Container as MapDiv, InfoWindow, Marker, NaverMap, useNavermaps } from 'react-naver-maps'
import useQueryGetMe from '@/api/auth/getMe.ts'
import useQueryGetUsers, { AlertStatus, DeviceStatus, IUser } from '@/api/user/getUsers.ts'
import clustering from 'density-clustering'
import ReactDOMServer from 'react-dom/server'
import MarkerWindow from '@/pages/dashboard/MarkerWindow.tsx'

interface IProps {
    className?: string;
}

export enum MarketTYPE {
    CLUSTER,
    SINGLE
}

export interface IMarker {
    id: number
    type?: MarketTYPE
    location?: IMarketLocation
    alertStatus?: AlertStatus
    deviceStatus?: DeviceStatus
    users: IUser[]
}

export interface IMarketLocation {
    latitude?: number
    longitude?: number
    accuracy?: number
    timestamp?: string
}

export interface IMarkerInfo extends IMarker {
    locationName?: string
    render?: any
    address: string
}

const CACHE__ZOOM_LEVEL = 'dashboard_map__zoom'
const CACHE__CENTER_X = 'dashboard_map__center__longitude'
const CACHE__CENTER_Y = 'dashboard_map__center__latitude'

const DashboardMapDefault = ({ className }: IProps, ref: any) => {
    const naverMaps = useNavermaps()
    const [map, setMap] = useState<any>(null)
    const [infoWindow, setInfoWindow] = useState<any>(null)
    const [userInfo, setUserInfo] = useState<IMarkerInfo>()
    const [zoomLevel, setZoomLevel] = useState(0)
    const { data: users } = useQueryGetUsers()
    const { data: me } = useQueryGetMe()

    useImperativeHandle(ref, () => ({
        setLocation(latitude: number, longitude: number, zoom?: number) {
            const location = new naverMaps.LatLng(
                latitude,
                longitude
            )
            map?.setCenter(location)
            if (zoom) {
                map?.setZoom(zoom)
            }
        }
    }))

    const openUserInfo = (info?: IMarker) => {
        if (!info || userInfo?.id == info?.id) {
            setUserInfo(undefined)
        } else {
            naverMaps.Service.reverseGeocode(
                {
                    coords: new naver.maps.LatLng(info?.location?.latitude || 0, info?.location?.longitude || 0),
                    orders: 'addr,roadaddr'
                },
                function(status, response) {
                    if (status !== naver.maps.Service.Status.OK) {
                        return alert('Something Wrong!')
                    }
                    const result = response?.v2?.address?.jibunAddress
                    const infoWithAddress: IMarkerInfo = {
                        ...info!,
                        // @ts-ignore
                        locationName: response?.v2?.results?.at(1)?.land?.addition0?.value || '',
                        address: result
                    }

                    infoWithAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithAddress} />)
                    setUserInfo(infoWithAddress)
                }
            )
        }
    }

    useEffect(() => {
        if (!map || !infoWindow) {
            return
        }
        if (userInfo) {
            infoWindow.open(map, new naver.maps.LatLng(userInfo?.location?.latitude || 0, userInfo?.location?.longitude || 0))
        } else {
            infoWindow.close()
        }
    }, [userInfo])

    const markers: IMarker[] = useMemo(() => {

        const filtered = users?.filter(item => item?.device?.status) || []

        const radius = new Map<number, number>()
        radius.set(21, 0.000005);
        radius.set(20, 0.00001);
        radius.set(19, 0.00002);
        radius.set(18, 0.00005);
        radius.set(17, 0.0001);
        radius.set(16, 0.0002);
        radius.set(15, 0.0005);
        radius.set(14, 0.001);
        radius.set(13, 0.002);
        radius.set(12, 0.005);
        radius.set(11, 0.01);
        radius.set(10, 0.02);
        radius.set(9, 0.05);
        radius.set(8, 0.1);
        radius.set(7, 0.2);
        radius.set(6, 0.5);
        radius.set(5, 1);
        radius.set(4, 2);
        radius.set(3, 5);
        radius.set(2, 10);
        radius.set(1, 20);
        // radius.set(7, 0.2)
        // radius.set(8, 0.1)
        // radius.set(9, 0.07)
        // radius.set(10, 0.05)
        // radius.set(11, 0.03)
        // radius.set(12, 0.01)
        // radius.set(13, 0.004)
        // radius.set(14, 0.0015)
        // radius.set(15, 0.0010)
        // radius.set(16, 0.0006)
        // radius.set(17, 0.00005)
        // radius.set(18, 0.00003)
        // radius.set(19, 0.00001)
        // radius.set(20, 0.00001)
        // radius.set(21, 0.000005)

        const dbscan = new clustering.DBSCAN()
        const clusters = dbscan.run(filtered?.map(item => [item?.location?.longitude || 0, item?.location?.latitude || 0]) || [], radius.get(zoomLevel) ?? 0.000005, 2)

        const markers: IMarker[] = []

        clusters?.map(ids => {
            const item = filtered[ids[0]]
            const filteredUsers = ids?.map(id => filtered[id])

            let alertStatus
            if (filteredUsers?.find(item => item?.alertStatus == AlertStatus.EMERGENCY)) {
                alertStatus = AlertStatus.EMERGENCY
            } else if (filteredUsers?.find(item => item?.alertStatus == AlertStatus.BIO)) {
                alertStatus = AlertStatus.BIO
            } else {
                alertStatus = AlertStatus.NONE
            }

            markers?.push({
                id: item?.id,
                alertStatus: alertStatus,
                location: {
                    latitude: item?.location?.latitude,
                    longitude: item?.location?.longitude,
                    accuracy: item?.location?.accuracy,
                    timestamp: item?.location?.timestamp
                },
                type: MarketTYPE.CLUSTER,
                users: ids?.map(id => filtered[id])
            })
        })

        dbscan?.noise?.map(id => {
            const item = filtered[id]
            markers?.push({
                id: item?.id,
                alertStatus: item?.alertStatus,
                location: {
                    latitude: item?.location?.latitude,
                    longitude: item?.location?.longitude,
                    accuracy: item?.location?.accuracy,
                    timestamp: item?.location?.timestamp
                },
                type: MarketTYPE.SINGLE,
                users: [item]
            })
        })

        return markers
    }, [users, zoomLevel])

    return <>
        <div className={`${className}`}>
            <MapDiv
                style={{
                    width: '100%',
                    height: '100%'
                }}>
                {me && <NaverMap
                    ref={setMap}
                    defaultCenter={new naverMaps.LatLng(Number(localStorage.getItem(CACHE__CENTER_Y)) || me?.latitude || 37.5759, Number(localStorage.getItem(CACHE__CENTER_X)) || me?.longitude || 126.9768)}
                    defaultZoom={Number(localStorage.getItem(CACHE__ZOOM_LEVEL)) || 18}
                    onZoomChanged={zoom => {
                        localStorage.setItem(CACHE__ZOOM_LEVEL, zoom?.toString())
                        setZoomLevel(zoom)
                    }}
                    onCenterChanged={location => {
                        localStorage.setItem(CACHE__CENTER_X, location?.x?.toString())
                        localStorage.setItem(CACHE__CENTER_Y, location?.y?.toString())
                    }}>
                    {userInfo?.type == MarketTYPE.SINGLE && <Circle key={`circle_${userInfo?.id}`}
                                                                    center={new naverMaps.LatLng(userInfo?.location?.latitude || 0, userInfo?.location?.longitude || 0)}
                                                                    radius={userInfo?.location?.accuracy}
                                                                    fillColor={'#ffffff99'} strokeColor={'#ffffffff'}
                    />}

                    {/* 마커 그리기 */}
                    {markers?.map(item => {
                        let renderBadge
                        if (item?.type == MarketTYPE.CLUSTER) {
                            switch (item?.alertStatus) {
                                case AlertStatus.EMERGENCY:
                                    renderBadge = `<div class="select-none min-w-[25px] min-h-[25px] z-[100] bg-sub_r rounded-full flex justify-center items-center text-white font-medium text-[12px] shadow border border-solid border-white" style="box-shadow: 0 0 4px 0 #FF003D" ><p>${item?.users?.length}</p></div>`
                                    break
                                case AlertStatus.BIO:
                                    renderBadge = `<div class="select-none min-w-[25px] min-h-[25px] z-[100] bg-[#FF7D33] rounded-full flex justify-center items-center text-white font-medium text-[12px] shadow border border-solid border-white" style="box-shadow: 0 0 4px 0 #FF7D33" ><p>${item?.users?.length}</p></div>`
                                    break
                                default:
                                    renderBadge = `<div class="select-none min-w-[25px] min-h-[25px] bg-main_b rounded-full flex justify-center items-center text-white font-medium text-[12px] shadow border border-solid border-white" style="box-shadow: 0 0 4px 0 #2966F4" ><p>${item?.users?.length}</p></div>`
                                    break
                            }
                        } else {
                            switch (item?.alertStatus) {
                                case AlertStatus.EMERGENCY:
                                    renderBadge = `<div class="w-[25px] h-[25px] select-none bg-sub_r rounded-full shadow border border-solid border-white" style="box-shadow: 0 0 4px 0 #FF003D"/>`
                                    break
                                case AlertStatus.BIO:
                                    renderBadge = `<div class="w-[25px] h-[25px] select-none bg-[#FF7D33] rounded-full shadow border border-solid border-white" style="box-shadow: 0 0 4px 0 #FF7D33"/>`
                                    break
                                default:
                                    renderBadge = `<div class="w-[25px] h-[25px] select-none bg-main_b rounded-full shadow border border-solid border-white" style="box-shadow: 0 0 4px 0 #2966F4"/>`
                                    break
                            }
                        }


                        return <Marker
                            key={`marker_${item?.id}`}
                            icon={{
                                content: renderBadge,
                                size: new naver.maps.Size(25, 25),
                                anchor: new naver.maps.Point(12.5, 12.5)
                            }}
                            defaultPosition={new naverMaps.LatLng(item?.location?.latitude || 0, item?.location?.longitude || 0)}
                            onClick={() => openUserInfo(item)} />
                    })}

                    {/* 상세 정보 창 그리기 */}
                    <InfoWindow ref={setInfoWindow}
                                content={userInfo?.render}
                                anchorSize={new naver.maps.Size(0, 35)}
                                borderColor={'#D9D9D9'} borderWidth={1} />
                </NaverMap>}
            </MapDiv>
        </div>
    </>
}

export default React.forwardRef(DashboardMapDefault)
import React from 'react'
import { IInputParams } from '@/component/input/Input.tsx'
import SearchIcon from '@/assets/ic_search_white.svg?react'

export interface IProps extends IInputParams {
    className?: string
    inputParams?: IInputParams
    onClick?: () => void
}

const SearchBar: React.FC<IProps> = (params: IProps) => {

    const onKeyDownEnter = (e: any) => {
        if (e.key === 'Enter') {
            params?.onPressEnter && params?.onPressEnter()
        }
    }
    return <div
        className={`${params?.className} flex items-center gap-[6px] justify-center whitespace-nowrap truncate bg-white border border-solid w-full border-main_b`}>
        <input
            {...params?.inputParams}
            onKeyDown={onKeyDownEnter}
            onChange={e => {
                const text = e.target.value
                params?.onChanged && params?.onChanged(text)
            }}
            className={'bg-transparent w-full pl-[15px] py-[8px]'} />
        <button className={'bg-main_b min-w-[40px] min-h-[40px] flex justify-center items-center'}
                onClick={params?.onClick}><SearchIcon />
        </button>
    </div>
}

export default SearchBar
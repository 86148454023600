import React from 'react'

export interface IMajorFeatureButtonProps {
    className?: string;
    id: string
    text: string
    icon: React.ReactNode
    iconDefaultColor?: string
    iconSelectedColor?: string
    isSelected?: boolean
    onClick?: (id: string) => void
}

const HomeMajorFeatureButton: React.FunctionComponent<IMajorFeatureButtonProps> = ({
                                                                                       id,
                                                                                       className,
                                                                                       icon,
                                                                                       iconDefaultColor = 'fill-gray2',
                                                                                       iconSelectedColor,
                                                                                       text = '버튼 예제',
                                                                                       isSelected = false,
                                                                                       onClick
                                                                                   }) => {
    return <>
        <button className={`${className} flex flex-col gap-[6.25px] justify-center items-center group`}
                onClick={() => onClick && onClick(id)}>
            <div
                className={`w-[60px] h-[60px] sm:w-[28px] sm:h-[28px] ${isSelected ? iconSelectedColor : iconDefaultColor} group-hover:fill-white`}>
                {icon}
            </div>
            <div
                className={`border border-solid rounded-[28.5px] sm:[13.4px] py-[11.5px] sm:py-[5px] px-[26px] sm:px-[12.27px] text-[20px] sm:text-[9.8px] font-medium  ${isSelected ? 'text-white bg-main_b' : 'text-main_b border-main_b'} group-hover:bg-white group-hover:text-main_b group-active:opacity-50`}>{text}</div>
        </button>
    </>
}

export default HomeMajorFeatureButton
import React from 'react'
import { Container as MapDiv, Marker, NaverMap, useNavermaps } from 'react-naver-maps'
import useIsMount from '@/util/hook/useIsMount.ts'

interface IProps {
    className?: string;
    latitude?: number
    longitude?: number
}

const DefaultMap: React.FunctionComponent<IProps> = ({ className, latitude, longitude }) => {
    const naverMaps = useNavermaps()
    const { isMount } = useIsMount()

    return <>
        <div className={`${className}`} onClick={e => e.stopPropagation()}>
            {isMount && latitude && longitude && <MapDiv
                style={{
                    width: '100%',
                    height: '100%'
                }}>
                <NaverMap
                    defaultCenter={new naverMaps.LatLng(latitude, longitude)}
                    defaultZoom={15}>
                    <Marker
                        icon={{
                            content: `<div class="w-[35px]" ><img src="/ic_map_marker.png"/></div>`,
                            size: new naver.maps.Size(35, 35),
                            anchor: new naver.maps.Point(17.5, 35)
                        }}
                        defaultPosition={new naverMaps.LatLng(latitude ?? 0, longitude ?? 0)}
                    />
                </NaverMap>
            </MapDiv>}
        </div>
    </>
}

export default DefaultMap
import React, { useContext, useMemo } from 'react'
import { IAlertBio } from '@/api/notification/getNotificationBio.ts'
import { format } from 'date-fns'
import GreenIcon from '@/assets/img__bio_alert__green.svg?react'
import RedIcon from '@/assets/img__bio_alert__red.svg?react'
import { BioAlertModalContext } from '@/pages/notification/bio/BioAlertModal.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps extends IAlertBio {
    className?: string
}

const BioAlertItem: React.FC<IProps> = ({ className, protectee, alert, id }) => {
    const { t } = useTranslation()

    const renderStatusIcon = useMemo(() => {
        if (alert?.taskManagerAt) {
            return <GreenIcon />
        } else {
            return <RedIcon />
        }
    }, [alert?.taskManagerAt])

    const { id: selectedId, setId } = useContext(BioAlertModalContext)

    const isSelected = useMemo(() => {
        return id == selectedId
    }, [id, selectedId])

    return <div
        className={`${className} rounded-[10px] flex justify-between items-center px-[20px] min-h-[40px] ${isSelected ? 'bg-main_b text-white' : 'bg-sub_b'} border ${!!alert?.isMyTask && selectedId != id ? 'border-sub_r' : 'border-transparent'} border-solid hover:border-main_b active:bg-main_b active:text-white cursor-pointer text-[14px]`}
        onClick={() => setId(id)}>
        {renderStatusIcon}
        <p>{protectee?.name}</p>
        <p>{format(alert?.createdAt, 'HH:mm:ss')}</p>
        <p>{alert?.taskManagerAt ? t('notification.bioModal.list.item.allotment') : t('notification.bioModal.list.item.new')}</p>
    </div>
}

export default BioAlertItem
import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'


export const apiGetUserRecentState = async (userId: number) => {
    return await apiGet<IResUserRecentState>(createUrl(`/protectee/${userId}/stats/recent`, {}))
}

const useQueryGetUserRecentState = (userId: number) => {
    return useQuery({
        enabled: userId > 0,
        queryKey: QueryCacheKeys.user.getUserRecentState(userId),
        queryFn: async () => {
            const { data } = await apiGetUserRecentState(userId)
            return data
        }
    })
}

export default useQueryGetUserRecentState

export interface IUserRecentSummary {
    isOnline: boolean
    respiration: number;
    hrate: number;
    respStatus: string;
    hrateStatus: string;
    battery?: any;
    isCharging?: boolean;
    step: number;
}

export interface IResUserRecentState {
    respBound: number[];
    hrateBound: number[];
    summary: IUserRecentSummary;
}
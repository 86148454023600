import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'
import { MINUTE } from '@/constant/time.ts'

export const apiGetEmergencyAlerts = async () => {
    return await apiGet<IResGetBioAlerts>(createUrl(`/alert/emergency/today`, {}))
}

const useQueryGetEmergencyAlerts = () => {
    return useQuery({
        queryKey: QueryCacheKeys.notification.getEmergencyAlertToday(),
        refetchInterval: MINUTE,
        queryFn: async () => {
            const { data } = await apiGetEmergencyAlerts()
            return data
        }
    })
}

export default useQueryGetEmergencyAlerts

export interface IBioAlertSimple {
    id: number;
    createdAt: string;
}

export interface IResGetBioAlerts {
    data: IBioAlertSimple[];
}
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEN from '@/locales/en/translation.json'
import translationKO from '@/locales/ko/translation.json'
import translationJA from '@/locales/ja/translation.json'

const resources = {
    en: {
        translation: translationEN
    },
    ko: {
        translation: translationKO
    },
    ja: {
        translation: translationJA
    }
}

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        detection: { order: ['localStorage', 'navigator'] },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
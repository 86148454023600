import React, { useMemo } from 'react'
import { DeviceStatus } from '@/api/user/getUsers.ts'
import BadgeOnline from '@/assets/ic_badge__device_status__online.svg?react'
import BadgeCharging from '@/assets/ic_badge__device_status__charging.svg?react'
import BadgeEmergency from '@/assets/ic_badge__device_status__emergency.svg?react'
import BadgeOffline from '@/assets/ic_badge__device_status__offline.svg?react'

export interface IProps {
    value?: DeviceStatus
    className?: string
}

const DeviceStatusBadge: React.FC<IProps> = ({ className, value }) => {

    const renderImage = useMemo(() => {
        switch (value) {
            case DeviceStatus.ONLINE:
                return <BadgeOnline className={''} />
            case DeviceStatus.CHARGING:
                return <BadgeCharging className={''} />
            case DeviceStatus.EMERGENCY:
                return <BadgeEmergency className={''} />
            case DeviceStatus.OFFLINE:
            default:
                return <BadgeOffline className={''} />

        }
    }, [value])

    return <div
        className={`${className} flex-none`}>
        {renderImage}
    </div>
}

export default DeviceStatusBadge
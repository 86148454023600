import React, { useEffect, useRef, useState } from 'react'
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole
} from '@floating-ui/react'

interface IProps {
    className?: string
    textClassName?: string
    text?: string
    children?: any
}

const ARROW_HEIGHT = 0
const GAP = 4

const AltTooltip: React.FunctionComponent<IProps> = (props) => {
    const { className, textClassName, children, text } = props
    const [isOpen, setIsOpen] = useState(false)
    const { x, y, strategy, refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [flip(), shift(), offset(ARROW_HEIGHT + GAP)],
        whileElementsMounted: autoUpdate
    })

    const hover = useHover(context)

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role, hover])


    // truncated detect
    const [isTruncated, setIsTruncated] = useState(false)
    const textRef = useRef<any>(null)

    useEffect(() => {
        const checkTruncation = () => {
            if (textRef.current) {
                setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
            }
        }

        checkTruncation()
        window.addEventListener('resize', checkTruncation)

        return () => {
            window.removeEventListener('resize', checkTruncation)
        }
    }, [text])

    return (
        <div className={`${className}`}>
            <div
                ref={refs?.setReference}
                {...getReferenceProps()}
                className={`${className} cursor-pointer`}>
                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                    <p
                        className={`${textClassName}`}
                        ref={textRef}
                        data-tip={isTruncated ? text : ''}
                        style={{
                            verticalAlign: 'top',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            width: '100%'
                        }}>{text}</p>
                </div>
            </div>
            {isOpen && isTruncated && (
                <div
                    ref={refs?.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content'
                    }}
                    {...getFloatingProps()}
                    className={'z-tooltip'}
                    onClick={e => {
                        setIsOpen(false)
                        e.stopPropagation()
                    }}>
                    <div className={`px-[4px] rounded-[5px] bg-sub_b border border-gray2 shadow-sm`}>
                        <div className={'text-body'}>{text}</div>
                        {children}
                    </div>
                </div>
            )}
        </div>)
}

export default AltTooltip
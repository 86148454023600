import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'


export const apiGetUserStatAverage = async (userId: number) => {
    return await apiGet<IResStatAverage>(createUrl(`/protectee/${userId}/stats/average`, {}))
}

const useQueryGetUserStatAverage = (userId: number) => {
    return useQuery({
        queryKey: QueryCacheKeys.user.getUserStatAverage(userId),
        queryFn: async () => {
            const { data } = await apiGetUserStatAverage(userId)
            return data
        }
    })
}

export default useQueryGetUserStatAverage

export interface IAverageHeartRate {
    day?: number;
    week?: number;
    month: number;
    year: number;
}

export interface IAverageRespiration {
    day?: number;
    week?: number;
    month: number;
    year: number;
}

export interface IAverageStep {
    day: number;
    week: number;
    month: number;
    year: number;
}

export interface IAverageDevicePowerOnMinute {
    day: number;
    week: number;
    month: number;
    year: number;
}

export interface IAverageDeviceWearMinute {
    day: number;
    week: number;
    month: number;
    year: number;
}

export interface IResStatAverage {
    hrate: IAverageHeartRate;
    respiration: IAverageRespiration;
    step: IAverageStep;
    devicePowerOnMinute: IAverageDevicePowerOnMinute;
    deviceWearMinute: IAverageDeviceWearMinute;
}
import React from 'react'

interface IProps {
    className?: string;
    children?: any
}

const GuideTitle: React.FunctionComponent<IProps> = ({ className, children }) => {
    return <>
        <p className={`${className} inline-block leading-[20px] px-[8px] text-white bg-black text-[14px] rounded-[5px]`}>
            {children}
        </p>
    </>
}

export default GuideTitle
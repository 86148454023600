interface FilteredParam {
    key: string
    value?: string
}

export const createUrl = (url: string, params?: { [n: string]: any }) => {
    if (!params) return url

    let queryText = ''

    const filteredParamList: FilteredParam[] = []
    const keys = Object.keys(params)
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        if (params[key]) {
            filteredParamList?.push({key: key, value: params[key]})
        }
    }

    filteredParamList?.map((item, index) => {
        if (index === 0) {
            queryText += `?${item.key}=${item.value}`
        } else {
            queryText += `&${item.key}=${item.value}`
        }
    })

    return `${url}${queryText}`
}

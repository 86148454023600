import React from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { equalString } from '@/util/strings.ts'

export interface INavItem {
    id: string
    label: string
    href: string
    tab?: string
}

export interface IProps extends INavItem {
    className?: string
}

const NotificationNavItem: React.FC<IProps> = ({ className, label, href, tab = '' }) => {
    const [searchParams] = useSearchParams()

    return (
        <div className={`${className}`}>
            <NavLink
                to={href}
                className={({ isActive }) =>
                    [
                        'flex items-center justify-center min-h-[60px] px-[15px] py-[5px] font-medium border-solid border-t border-x rounded-t-[5px] text-[16px] min-w-[163px]',
                        isActive && equalString(tab, searchParams?.get('tab') || '') ? 'text-main_p' : 'text-gray3'
                    ].join(' ')}>
                {label}
            </NavLink>
        </div>
    )
}

export default NotificationNavItem

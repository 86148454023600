import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface IProps {
    value?: string
    className?: string
}

const AlertConfirmStatusBadge: React.FC<IProps> = ({ className, value }) => {
    const { t } = useTranslation()

    const typeClassName = useMemo(() => {
        switch (value) {
            case '미처리':
            case '담당자 배정':
                return 'border-sub_r text-sub_r'
            case '확인':
                return 'border-main_b text-main_b'
            default:
                return 'border-gray3 text-gray3'
        }
    }, [value])

    const typeName = useMemo(() => {
        switch (value) {
            case '미처리':
                return t('notification.confirm.status.wait')
            case '담당자 배정':
                return t('notification.confirm.status.manager')
            case '확인':
                return t('notification.confirm.status.confirmed')
            case '처리':
                return t('notification.confirm.status.done')
            default:
                return value
        }
    }, [value, t])


    return <div
        className={`${className} border border-solid inline-block rounded-[5px] px-[2px] py-[3px] text-[10px] whitespace-nowrap ${typeClassName}`}>
        {typeName}
    </div>
}

export default AlertConfirmStatusBadge
import { Navigate } from 'react-router-dom'
import { useLoginStore } from '@/store/LoginStore.ts'
import { isNotEmpty } from '@/util/strings.ts'

interface IProps {
    href?: string
    children: any
}

const PrivateRoute = ({ href = '/login', children }: IProps) => {
    const { accessToken } = useLoginStore()
    const authenticate = isNotEmpty(accessToken)
    return authenticate ? (
        children
    ) : (
        <Navigate
            to={href}
        />
    )
}

export default PrivateRoute
import { apiPatch } from '@/api/api.ts'

export interface IReqPatchBioAlertAssignPerson {
    manager_name: string;
}

export const apiPatchEmergencyAlertAssignPerson = async (id: number, params: IReqPatchBioAlertAssignPerson) => {
    return await apiPatch<undefined>(`/alert/emergency/${id}`, {
        type: '담당자 배정',
        manager_name: params.manager_name
    })
}

export interface IReqPatchBioAlertProcess {
    task_content: string;
    task_result: string;
}

export const apiPatchEmergencyAlertProcess = async (id: number, params: IReqPatchBioAlertProcess) => {
    return await apiPatch<undefined>(`/alert/emergency/${id}`, {
        type: '확인 완료',
        task_result: params.task_result,
        task_content: params.task_content
    })
}


import React from 'react'

interface IProps {
    className?: string
    imageClassName?: string
    src?: string
}

const AdminThumbnail: React.FunctionComponent<IProps> = ({ className, imageClassName, src }) => {
    return <>
        <div
            className={`${className} flex items-center justify-center border border-solid border-gray2 rounded-full`}>
            <img src={src ?? '/empty'} className={`rounded-full ${imageClassName} object-cover`}
                 onError={({ currentTarget }) => {
                     currentTarget.onerror = null // prevents looping
                     currentTarget.src = '/img_avatar_gray.png'
                 }} />
        </div>
    </>
}

export default AdminThumbnail
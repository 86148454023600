import { API_ENDPOINT } from '../constant/constant.ts'
import { getCookie } from '@/util/cookie.ts'
import { KEY_ACCESS_TOKEN } from '@/store/LoginStore.ts'

export interface IApiGetProps {
    cookies?: {}
}

export interface ApiRes<T> {
    code: number
    message?: string
    data?: T
}

export const apiGet = async <T>(url: string, isMocking: boolean = false): Promise<ApiRes<T>> => {
    let endpoint = `${API_ENDPOINT}${url}`
    if (isMocking) {
        endpoint = url
    }

    const response = await fetch(endpoint, {
        method: 'GET',
        headers: getHeaders()
    })
    const res = await response.json()
    return { data: res.data, code: res.code, message: res.message }
}

export const apiPost = async <T>(url: string, body?: any, isMocking: boolean = false): Promise<ApiRes<T>> => {
    let endpoint = `${API_ENDPOINT}${url}`
    if (isMocking) {
        endpoint = url
    }

    const response = await fetch(endpoint, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body)
    })
    const res = await response.json()
    return { data: res.data, code: res.code, message: res.message }
}

export const apiPostFormData = async <T>(url: string, body?: any): Promise<ApiRes<T>> => {
    let endpoint = `${API_ENDPOINT}${url}`

    let formData = new FormData()

    const keys = Object.keys(body)
    for (let i = 0; i < keys.length; i++) {
        formData.append(keys[i], body[keys[i]])
    }

    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            Accept: 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${getCookie(KEY_ACCESS_TOKEN)}`
        },
        body: formData

    })
    const res = await response.json()
    return { data: res.data, code: res.code, message: res.message }
}

export const apiPatch = async <T>(url: string, body?: any): Promise<ApiRes<T>> => {
    let endpoint = `${API_ENDPOINT}${url}`

    const response = await fetch(endpoint, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(body)

    })
    const res = await response.json()
    return { data: res.data, code: res.code, message: res.message }
}

export const apiDelete = async <T>(url: string, body?: any): Promise<ApiRes<T>> => {
    let endpoint = `${API_ENDPOINT}${url}`

    const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(body)

    })
    const res = await response.json()
    return { data: res.data, code: res.code, message: res.message }
}

export const apiPatchFormData = async <T>(url: string, body?: any): Promise<ApiRes<T>> => {
    let endpoint = `${API_ENDPOINT}${url}`

    let formData = new FormData()

    const keys = Object.keys(body)
    for (let i = 0; i < keys.length; i++) {
        formData.append(keys[i], body[keys[i]])
    }

    const response = await fetch(endpoint, {
        method: 'PATCH',
        headers: {
            Accept: 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${getCookie(KEY_ACCESS_TOKEN)}`
        },
        body: formData

    })
    const res = await response.json()
    return { data: res.data, code: res.code, message: res.message }
}


type Headers = {
    Accept: string
    'Content-Type': string
    Authorization?: string
}

const getHeaders = (): Headers => {
    return {
        Accept: 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie(KEY_ACCESS_TOKEN)}`
    }
}

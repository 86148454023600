import React, { useEffect, useMemo, useState } from 'react'
import { Circle, Container as MapDiv, InfoWindow, Marker, NaverMap, useNavermaps } from 'react-naver-maps'
import useQueryGetUserDetail from '@/api/auth/getUser.ts'
import useIsMount from '@/util/hook/useIsMount.ts'
import { IMarker, IMarkerInfo, MarketTYPE } from '@/pages/dashboard/DashboardMapDefault.tsx'
import ReactDOMServer from 'react-dom/server'
import AlertStatusBadge from '@/component/badge/AlertStatusBadge.tsx'
import MarkerWindow from '@/pages/dashboard/MarkerWindow.tsx'

interface IProps {
    className?: string;
    userID?: number
    defaultOpenInfo?: boolean
}

const UserDetailMap: React.FunctionComponent<IProps> = ({ className, userID = 0, defaultOpenInfo = false }) => {
    const naverMaps = useNavermaps()
    const [map, setMap] = useState<any>(null)
    const { data } = useQueryGetUserDetail(userID)
    const [infoWindow, setInfoWindow] = useState<any>(null)
    const { isMount } = useIsMount()
    const [userInfo, setUserInfo] = useState<any>(null)

    const openUserInfo = (info?: IMarker) => {
        if (!info || userInfo?.id == info?.id) {
            setUserInfo(undefined)
        } else {
            naverMaps.Service.reverseGeocode(
                {
                    coords: new naver.maps.LatLng(info?.location?.latitude || 0, info?.location?.longitude || 0),
                    orders: 'addr,roadaddr'
                },
                function(status, response) {
                    if (status !== naver.maps.Service.Status.OK) {
                        return alert('Something Wrong!')
                    }
                    const result = response?.v2?.address?.jibunAddress
                    const infoWithAddress: IMarkerInfo = {
                        ...info!,
                        // @ts-ignore
                        locationName: response?.v2?.results?.at(1)?.land?.addition0?.value || '',
                        address: result
                    }
                    infoWithAddress.render = ReactDOMServer.renderToString(<MarkerWindow info={infoWithAddress} />)
                    setUserInfo(infoWithAddress)
                }
            )
        }
    }

    const marker = useMemo(() => {
        if (!data) return undefined
        return {
            id: userID,
            deviceStatus: data?.device?.status,
            location: {
                latitude: data?.location?.latitude,
                longitude: data?.location?.longitude,
                accuracy: data?.location?.accuracy,
                timestamp: data?.location?.timestamp
            },
            type: MarketTYPE.SINGLE,
            users: [data]
        }
    }, [data])

    useEffect(() => {
        if (!map || !infoWindow) {
            return
        }

        if (userInfo) {
            infoWindow.open(map, new naver.maps.LatLng(userInfo?.location?.latitude, userInfo?.location?.longitude))
        } else {
            infoWindow.close()
        }
    }, [userInfo])

    useEffect(() => {
        if (defaultOpenInfo && data?.location) {
            openUserInfo(marker)
        }
    }, [defaultOpenInfo, data])

    return <>
        <div className={`${className}`}>
            {isMount && data?.device ? <MapDiv
                    key={userID}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}>
                    <NaverMap
                        ref={setMap}
                        defaultCenter={new naverMaps.LatLng(data?.location?.latitude || 0, data?.location?.longitude || 0)}
                        defaultZoom={15}>
                        <Circle center={new naverMaps.LatLng(data?.location?.latitude || 0, data?.location?.longitude || 0)}
                                radius={data?.location?.accuracy}
                                fillColor={'#ffffff99'} strokeColor={'#ffffffff'}
                        />
                        <Marker
                            icon={{
                                content: ReactDOMServer.renderToString(<AlertStatusBadge
                                    size={25}
                                    className={'cursor-pointer'}
                                    value={data?.alertStatus} />),
                                size: new naver.maps.Size(25, 25),
                                anchor: new naver.maps.Point(12.5, 12.5)
                            }}
                            defaultPosition={new naverMaps.LatLng(data?.location?.latitude ?? 0, data?.location?.longitude ?? 0)}
                            onClick={() => openUserInfo(marker)}
                        />
                        <InfoWindow ref={setInfoWindow}
                                    content={userInfo?.render}
                                    anchorSize={new naver.maps.Size(0, 42)}
                                    borderColor={'#D9D9D9'} borderWidth={1} />
                    </NaverMap>
                </MapDiv> :
                <div className={'bg-gray1 h-full text-body text-center content-center'}>
                    기기를 연결해주세요
                </div>}
        </div>
    </>
}

export default UserDetailMap
import React, { useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
import SelectArrow from '@/assets/ic_arrow_select.svg?react'
import { equalString } from '@/util/strings.ts'

export interface ISelectOption {
    id: string
    text?: string
    value?: any
}

interface IProps {
    className?: string
    onChanged: (value: ISelectOption) => void
    options: Array<ISelectOption>
    selectedId?: string
    value?: ISelectOption
    disabled?: boolean
}


const Select: React.FC<IProps> = ({
                                      onChanged,
                                      options = [],
                                      className,
                                      disabled = false,
                                      selectedId,
                                      value
                                  }) => {
    const [selectValue, setSelectValue] = useState(options[0])

    useEffect(() => {
        const selectedOption = options?.find(item => equalString(item?.id, selectedId))
        if (selectedOption) {
            setSelectValue(selectedOption)
            onChanged(selectedOption)
        }
    }, [selectedId])

    useEffect(() => {
        setSelectValue(value ?? options[0])
    }, [value])

    return (
        <div className={`min-w-[100px] ${className}`}>
            <Listbox value={selectValue} onChange={setSelectValue} disabled={disabled}>
                {({ open }) => {
                    return (
                        <div
                            className={`relative w-full box-border  cursor-pointer ${
                                disabled ? 'bg-[#F5F5F5]' : 'bg-sub_b'
                            }`}>
                            <Listbox.Button
                                className={`flex justify-between w-full py-[12px] pl-[15px] pr-[13px]`}>
                                <p className={`text-input text-[14px] font-normal`}>{selectValue?.text}</p>
                                <SelectArrow className={''} />
                            </Listbox.Button>

                            {open && (
                                <Listbox.Options
                                    className={`absolute select-none w-full mt-2.5 z-dropdown max-h-[348px] overflow-y-auto overflow-hidden border border-solid border-main_b divide-y divide-solid divide-main_b bg-white`}>
                                    {options.map(option => {
                                        return (
                                            <Listbox.Option
                                                className={
                                                    'relative select-none py-[13px] px-[16px] w-full flex items-center font-medium hover:text-white hover:bg-main_b text-center justify-center cursor-pointer'
                                                }
                                                key={option.id}
                                                value={option}
                                                onClick={() => {
                                                    onChanged(option)
                                                }}>
                                                <p className={`whitespace-nowrap truncate`}>
                                                    {option.text}
                                                </p>
                                            </Listbox.Option>
                                        )
                                    })}
                                </Listbox.Options>
                            )}
                        </div>
                    )
                }}
            </Listbox>
        </div>
    )
}

export default Select

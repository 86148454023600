import React from 'react'

interface IProps {
    className?: string;
    text: string
    onClick: () => void
}

const TopNavMobileButton: React.FunctionComponent<IProps> = ({ className, text, onClick }) => {
    return <>
        <button className={`${className} bg-white text-black min-h-[60px] hover:text-white hover:bg-main_b font-medium`}
                onClick={onClick}>
            {text}
        </button>
    </>
}

export default TopNavMobileButton
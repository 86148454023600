import React from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import { equalString } from '@/util/strings.ts'
import NotificationLayout from '@/pages/notification/layout/NotificationLayout.tsx'
import { useSearchParams } from 'react-router-dom'
import NotificationEmergencyContainer from '@/pages/notification/NotificationEmergencyContainer.tsx'
import NotificationBioContainer from '@/pages/notification/NotificationBioContainer.tsx'

interface IProps {
}

const NotificationPage: React.FunctionComponent<IProps> = ({}) => {
    const [searchParams] = useSearchParams()

    return (
        <>
            <PageConfig showSideNav={true} showTopNav={true}>
                <NotificationLayout>
                    {equalString(searchParams?.get('tab') || '', 'sos') && <NotificationEmergencyContainer />}
                    {equalString(searchParams?.get('tab') || '', 'bio') && <NotificationBioContainer />}
                </NotificationLayout>
            </PageConfig>
        </>
    )
}

export default NotificationPage
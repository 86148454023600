import { useEffect, useState } from 'react'

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                // @ts-ignore
                width: window.innerWidth,
                // @ts-ignore
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const isSm = windowSize.width && windowSize.width <= 599
    const isMd = windowSize.width && windowSize.width <= 1023
    const isLg = windowSize.width && windowSize.width > 1023

    return { windowSize, isSm, isMd, isLg }
}

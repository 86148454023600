import React, { useMemo } from 'react'
import useQueryGetMe from '@/api/auth/getMe.ts'
import SideNavItem, { INavItem } from '@/component/layout/SideNav/SideNavItem.tsx'
import AdminThumbnail from '@/component/image/AdminThumbnail.tsx'
import { useTranslation } from 'react-i18next'

type IProps = {
    className?: string;
}

const SideNav: React.FunctionComponent<IProps> = ({ className }) => {
    const { t } = useTranslation()
    const { data: me } = useQueryGetMe()

    const navItems = useMemo(() => {
        const items: INavItem[] = []
        items.push({
            id: 'dashboard',
            label: t('sideNav.menu.dashboard'),
            href: `/dashboard`
        })
        items.push({
            id: 'user',
            label: t('sideNav.menu.users'),
            href: `/user`
        })

        items.push({
            id: 'notification',
            label: t('sideNav.menu.notification'),
            href: `/notification`
        })

        items.push({
            id: 'hrv-analysis',
            label: t('sideNav.menu.mentalHealth'),
            href: `/mental`,
            disabled: true
        })

        items.push({
            id: 'connecting-crew',
            label: t('sideNav.menu.connectingCrew'),
            href: `/mental`,
            disabled: true
        })
        return items
    }, [t])

    return <>
        <div className={`${className} w-[196px] border-r border-solid border-gray2`}>
            <div className={'flex flex-col justify-center items-center mt-[48px]'}>
                <AdminThumbnail src={me?.profileUrl} imageClassName={'w-[100px] h-[100px]'} />
                <p className={'mt-[20px] text-body font-bold text-black'}>{me?.name}</p>
            </div>
            <div className={'mt-[50px]'}>
                {navItems?.map(item => <SideNavItem {...item} key={item?.id} />)}
            </div>
        </div>
    </>
}

export default SideNav
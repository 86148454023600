import React, { useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HomeMajorFeatureItem from '@/pages/landing/Home/MajorFeature/HomeMajorFeatureItem.tsx'
import Feature1 from '@/assets/img_home_feature_carousel1.jpg'
import Feature2 from '@/assets/img_home_feature_carousel2_1.jpg'
import Feature3 from '@/assets/img_home_feature_carousel3_2.jpg'
import Feature4 from '@/assets/img_home_feature_carousel4_2.jpg'
import useWindowSize from '@/util/hook/useWindowSize.ts'
import HomeMajorFeatureButton, {
    IMajorFeatureButtonProps
} from '@/pages/landing/Home/MajorFeature/HomeMajorFeatureButton.tsx'
import { equalString } from '@/util/strings.ts'
import BioDataImg from '@/assets/ic_home_major_btn_bio.svg?react'
import AlertImg from '@/assets/ic_home_major_btn_alert.svg?react'
import LocationImg from '@/assets/ic_home_major_btn_location.svg?react'
import ManageImg from '@/assets/ic_home_major_btn_manage.svg?react'
import { useIsVisible } from '@/util/hook/useIsVisible.tsx'
import { useTranslation } from 'react-i18next'
import TagP from '@/component/text/TagP.tsx'

export interface IProps {
    className?: string
}

const HomeMajorFeature: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()
    const { isMd } = useWindowSize()
    const [selectedButtonId, setSelectedButtonId] = useState('bio_data')
    const sliderRef = useRef<any>(null)
    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: isMd ? '0px' : '100px',
        slidesToShow: 1,
        speed: 500,
        arrows: false
    }

    const buttonData: IMajorFeatureButtonProps[] = useMemo(() => {
        return [
            {
                id: 'bio_data',
                text: t('home.feature.options.bio.title'),
                icon: <BioDataImg />,
                iconSelectedColor: 'fill-main_b'
            },
            {
                id: 'urgent_notification',
                text: t('home.feature.options.emergency.title'),
                icon: <AlertImg />,
                iconSelectedColor: 'fill-sub_r'
            },
            {
                id: 'user_location',
                text: t('home.feature.options.location.title'),
                icon: <LocationImg />,
                iconSelectedColor: 'fill-main_b'
            },
            {
                id: 'manage_user',
                text: t('home.feature.options.users.title'),
                icon: <ManageImg />,
                iconSelectedColor: 'fill-main_b'
            }
        ]
    }, [t])

    const containerRef = useRef<any>()
    const isVisible = useIsVisible(containerRef, true)

    return <div className={`${className} bg-sub_b`}>
        {/* Desktop */}
        <div
            className={`py-[140px] sm:py-[60px] transition-opacity ease-in duration-[1000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`}
            ref={containerRef}>
            <h2 className={'text-[48px] sm:text-[24px] font-bold text-center text-main_b'}>{t('home.feature.title')}</h2>

            <div className={'flex gap-[24px] justify-center mt-[43px] sm:gap-[10.88px]'}>
                {buttonData?.map(item => <HomeMajorFeatureButton
                    key={item?.id} id={item?.id} text={item?.text}
                    icon={item?.icon}
                    onClick={(id) => {
                        setSelectedButtonId(id)
                        const index = buttonData?.findIndex(item => equalString(id, item?.id))
                        sliderRef?.current?.slickGoTo(index)
                    }}
                    iconSelectedColor={item?.iconSelectedColor}
                    isSelected={equalString(item?.id, selectedButtonId)} />)}
            </div>

            <Slider ref={sliderRef} {...settings} className={'mt-[50px] sm:mt-[30px]'}
                    beforeChange={(_, next) => {
                        setSelectedButtonId(buttonData.at(next)!.id)
                    }}>
                <HomeMajorFeatureItem className={'flex flex-col justify-center items-center'}>
                    <img src={Feature1} />
                    <TagP className={'mt-[24px] text-[20px] sm:text-[10px]'}>
                        {t('home.feature.options.bio.desc')}
                    </TagP>
                </HomeMajorFeatureItem>
                <HomeMajorFeatureItem className={'flex flex-col justify-center items-center'}>
                    <img src={Feature2} />
                    <TagP className={'mt-[24px] text-[20px] sm:text-[10px]'}>
                        {t('home.feature.options.emergency.desc')}
                    </TagP>
                </HomeMajorFeatureItem>
                <HomeMajorFeatureItem className={'flex flex-col justify-center items-center'}>
                    <img src={Feature3} />
                    <TagP className={'mt-[24px] text-[20px] sm:text-[10px]'}>
                        {t('home.feature.options.location.desc')}
                    </TagP>
                </HomeMajorFeatureItem>
                <HomeMajorFeatureItem className={'flex flex-col justify-center items-center'}>
                    <img src={Feature4} />
                    <TagP className={'mt-[24px] text-[20px] sm:text-[10px]'}>
                        {t('home.feature.options.users.desc')}
                    </TagP>
                </HomeMajorFeatureItem>

            </Slider>
        </div>
    </div>
}

export default HomeMajorFeature
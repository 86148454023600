import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { IPagingReq, IPagingRes } from '@/api/paging.ts'
import { createUrl } from '@/util/url.ts'
import { IPosition } from '@/api/user/getUsers.ts'

interface IReqGetUrgentHistory extends IPagingReq {

}

export const apiGetNotificationSos = async ({ pageNum, pageSize }: IReqGetUrgentHistory, query?: string) => {
    return await apiGet<IPagingRes<IAlertEmergency[]>>(createUrl('/alert/emergency', {
        query: query,
        pageNum: pageNum,
        pageSize: pageSize
    }))
}

const useQueryGetNotificationSos = (props: IReqGetUrgentHistory, query?: string) => {
    return useQuery({
        queryKey: QueryCacheKeys.notification.apiGetNotificationSos(query),
        queryFn: async () => {
            const { data } = await apiGetNotificationSos(props, query)
            return data
        }
    })
}

export default useQueryGetNotificationSos

export interface IAlertSosProtectee {
    id: number;
    name: string;
    gender: string;
    birthDate: string;
    contact: string;
    memo: string;
}


export interface IAlertEmergencyAlert {
    name: string;
    nameDetail?: IAlertStateDetail;
    managerName: string;
    confirm: EmergencyAlertConfirmStatus;
    result?: any;
    content?: string;
    isMyTask?: boolean;
    taskCheckAt?: string;
    taskManagerAt?: string;
    createdAt: string;
    checkedAt?: any;
    location: IPosition;
}

export interface IAlertEmergency {
    id: number;
    protectee: IAlertSosProtectee;
    protector: IAlertSosProtector;
    alert: IAlertEmergencyAlert;
}

export interface IAlertSosProtector {
    relation?: string;
    contact?: string;
    secondaryRelation?: string;
    secondaryContact?: string;
    tertiaryRelation?: string;
    tertiaryContact?: string;
}

export interface IAlertStateDetail {
    resp: number;
    hrate: number;
    respStatus: string;
    hrateStatus: string;
}

export enum EmergencyAlertConfirmStatus {
    WAIT = '미처리',
    ASSIGN = '담당자 배정',
    DONE = '처리'
}
import React, { useCallback, useMemo } from 'react'
import { format } from 'date-fns'
import { CartesianGrid, ComposedChart, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
    min?: number
    max?: number
    data?: IChartItem[]
}

export interface IChartItem {
    x: number // timestamp or Date object
    y: number
    yMin: number
    yMax: number
    range: [number, number]
}

// 데이터 정규화 함수
const normalizeData = (data: IChartItem[]) => {
    return data.map(item => {
        // y, yMin, yMax를 배열로 모아 정렬한 후 다시 할당
        const values = [item.y, item.yMin, item.yMax].sort((a, b) => a - b)
        return {
            ...item,
            yMin: values[0],  // 가장 작은 값
            y: values[1],      // 중간 값
            yMax: values[2],    // 가장 큰 값
            range: [values[0], values[2]]  // yMin과 yMax 사이를 range로 설정
        }
    })
}

const CustomTooltip = ({ active, payload }: any) => {
    const { t } = useTranslation()

    const getTitle = useCallback((value: string) => {
        switch (value) {
            case '최대':
                return t('users.realtimeChart.tooltip.max.title')
            case '평균':
                return t('users.realtimeChart.tooltip.average.title')
            case '최소':
                return t('users.realtimeChart.tooltip.min.title')
        }
    }, [t])

    if (active && payload && payload.length) {
        const x = payload[0].payload.x // X값 (시간)
        return (
            <div
                className="min-w-[83px] py-[7px] px-[7px] pb-[3px] bg-white bg-opacity-100 rounded-lg shadow border border-solid border-main_b">
                <p className="text-[20px] text-black text-center">
                    {format(x, 'HH:mm')}
                </p>
                {payload.map((item: any, index: number) => {
                    let textColor = ''
                    if (item.name === '최대') {
                        textColor = 'text-red-500' // 빨간색
                    } else if (item.name === '평균') {
                        textColor = 'text-green-500' // 녹색
                    } else if (item.name === '최소') {
                        textColor = 'text-blue-500' // 파란색
                    }

                    return (
                        <p key={index} className={`text-[20px] leading-[24px] text-center font-bold ${textColor}`}>
                            {getTitle(item.name)} : {Math.floor(item.value).toFixed(0)}bpm
                        </p>
                    )
                })}
            </div>
        )
    }
    return null
}

const DailyChart: React.FC<IProps> = ({ className, data, min = 60, max = 100 }) => {
    // 데이터 정규화
    const normalizedData = useMemo(() => normalizeData(data || []), [data])

    // 도메인 계산
    const domains = useMemo(() => {
        const minValues: number[] = normalizedData?.map(item => item?.yMin || item?.y) || []
        const maxValues: number[] = normalizedData?.map(item => item?.yMax || item?.y) || []

        const minDomain = Math.min(minValues.reduce((a, b) => Math.min(a, b), Infinity), min)
        const maxDomain = Math.max(maxValues.reduce((a, b) => Math.max(a, b), -Infinity), max)

        return [Math.max(minDomain - 5, 0), maxDomain + 5]
    }, [normalizedData])

    return (
        <div className={`${className}`}>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart data={normalizedData} margin={{ top: 0, left: 0, right: 8, bottom: 0 }}>
                    <CartesianGrid />
                    {/* X축에 시간값을 적용 */}
                    <XAxis dataKey="x" tickFormatter={x => format(x, 'HH:mm')} />
                    <YAxis domain={domains} tickFormatter={(value) => value?.toFixed(0)} />
                    <Tooltip content={<CustomTooltip />} />

                    {/* 하이라이트 영역 추가 */}
                    <ReferenceArea y1={min} y2={max} fill={'#F0F5FF'} stroke={'#2966F4'} strokeDasharray={'4 3'} />

                    {/* Max Line */}
                    <Line
                        type="monotone"
                        dataKey="yMax"
                        strokeWidth={2}
                        isAnimationActive={true}
                        stroke={'#FF003D'}
                        dot={{ r: 6 }} // 점 크기를 3배로 설정
                        name="최대"
                        connectNulls={true}
                        animationDuration={1500}   // 애니메이션 지속 시간 조정
                        animationEasing="ease-out" // 애니메이션 효과 설정
                    />

                    {/* Median Line */}
                    <Line
                        type="monotone"
                        dataKey="y"
                        strokeWidth={2}
                        isAnimationActive={true}
                        stroke={'#1AC60FD8'}
                        dot={{ r: 6 }} // 점 크기를 3배로 설정
                        name="평균"
                        connectNulls={true}
                        animationDuration={1500}   // 애니메이션 지속 시간 조정
                        animationEasing="ease-out" // 애니메이션 효과 설정
                    />

                    {/* Min Line */}
                    <Line
                        type="monotone"
                        dataKey="yMin"
                        strokeWidth={2}
                        isAnimationActive={true}
                        stroke={'#2966F4'}
                        dot={{ r: 6 }} // 점 크기를 3배로 설정
                        name="최소"
                        connectNulls={true}
                        animationDuration={1500}   // 애니메이션 지속 시간 조정
                        animationEasing="ease-out" // 애니메이션 효과 설정
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default DailyChart
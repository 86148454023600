import React, { useMemo } from 'react'
import { AlertStatus } from '@/api/user/getUsers.ts'

export interface IProps {
    value?: AlertStatus
    className?: string
    size?: number
}

const AlertStatusBadge: React.FC<IProps> = ({ className, value, size = 15 }) => {

    const renderImage = useMemo(() => {
        switch (value) {
            case AlertStatus.EMERGENCY:
                return <div
                    className="select-none bg-sub_r rounded-full shadow border border-solid border-white"
                    style={{ boxShadow: '0 0 4px 0 #FF003D', width: size, height: size }} />
            case AlertStatus.BIO:
                return <div
                    className="select-none bg-[#FF7D33] rounded-full shadow border border-solid border-white"
                    style={{ boxShadow: '0 0 4px 0 #FF7D33', width: size, height: size }} />
            default:
                return <div
                    className="select-none bg-main_b rounded-full shadow border border-solid border-white"
                    style={{ boxShadow: '0 0 4px 0 #2966F4', width: size, height: size }} />
        }
    }, [value])

    return <div
        className={`${className} flex-none flex items-center justify-center`}>
        {renderImage}
    </div>
}

export default AlertStatusBadge
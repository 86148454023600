import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Table, { ITableColumn } from '@/component/table/Table.tsx'
import Pagination from '@/component/pagination/Pagination.tsx'
import useQueryGetNotificationSos, { IAlertEmergency } from '@/api/notification/getNotificationSos.ts'
import { format } from 'date-fns'
import AlertConfirmStatusBadge from '@/component/badge/AlertConfirmStatusBadge.tsx'
import SearchBar from '@/component/input/SearchBar.tsx'
import useDebounce from '@/util/hook/useDebounce.tsx'
import ExcelDownloadIcon from '@/assets/ic_notification_sos_excel_download.svg?react'
import Tooltip from '@/component/tooltip/Tooltip.tsx'
import Input from '@/component/input/Input.tsx'
import SolidButton from '@/component/button/SolidButton.tsx'
import { isEmpty } from '@/util/strings.ts'
import { API_ENDPOINT } from '@/constant/constant.ts'
import { createUrl } from '@/util/url.ts'
import { getCookie } from '@/util/cookie.ts'
import { KEY_ACCESS_TOKEN } from '@/store/LoginStore.ts'
import EmergencyAlertModal from '@/pages/notification/emergency/EmergencyAlertModal.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

const PAGE_SIZE = 10

const NotificationEmergencyContainer: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()

    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [detailModalId, setDetailModalId] = useState<number>(-1)
    const detailModalRef = useRef<any>()
    const debouncedSearchText = useDebounce(search, 200)

    useEffect(() => {
        setCurrentPage(1)
    }, [debouncedSearchText])

    const { data, refetch } = useQueryGetNotificationSos({
        pageNum: currentPage,
        pageSize: PAGE_SIZE
    }, debouncedSearchText)


    useEffect(() => {
        refetch()
    }, [currentPage])

    const getGenderName = useCallback((value: string) => {
        switch (value) {
            case '남':
                return t('notification.sos.table.gender.male')
            case '여':
                return t('notification.sos.table.gender.female')
            default:
                return value
        }
    }, [t])

    const getResultName = useCallback((value: string) => {
        switch (value) {
            case '오작동':
                return t('notification.sos.table.process.malfunction')
            case '기타':
                return t('notification.sos.table.process.etc')
            case '119출동':
                return t('notification.sos.table.process.119')
            default:
                return value
        }
    }, [t])

    const columns = useMemo<ITableColumn<IAlertEmergency>[]>(() => {
        const result: ITableColumn<IAlertEmergency>[] = [
            {
                key: 'alert_time',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.sos.table.emergency.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p className={'text-center'}>{format(item?.alert?.createdAt, 'yyyy/MM/dd HH:mm')}</p>
                }
            },
            {
                key: 'checked_time',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.sos.table.confirmed.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{item?.alert?.checkedAt && format(item?.alert?.checkedAt, 'yyyy/MM/dd HH:mm')}</p>
                }
            },
            {
                key: 'manager_name',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.sos.table.manager.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{item?.alert?.managerName}</p>
                }
            },
            {
                key: 'protectee_name',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.sos.table.userName.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{item?.protectee?.name}</p>
                }
            },
            {
                key: 'protectee_gender_birthday',
                header: () => <p
                    className={'font-medium text-[14px]'}>{t('notification.sos.table.gender.column')} / {t('notification.sos.table.birthday.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{getGenderName(item?.protectee?.gender)} / {format(item?.protectee?.birthDate, 'yy-**-**')}</p>
                }
            },
            {
                key: 'protectee_contact',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.sos.table.contact.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{item?.protectee?.contact?.split('-')[0]}-****-{item?.protectee?.contact?.split('-')[2]}</p>
                }
            },
            {
                key: 'alert_location',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.sos.table.address.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{item?.alert?.location?.address?.split(" ").slice(0, -2).join(" ")}</p>
                }
            },
            {
                key: 'status',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.sos.table.status.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <div className={'flex justify-center items-center'}><AlertConfirmStatusBadge
                        value={item?.alert?.confirm} /></div>
                }
            },
            {
                key: 'alert_type',
                header: () => <p className={'font-medium text-[14px]'}>{t('notification.sos.table.process.column')}</p>,
                thClassName: 'bg-[#F0F5FF]',
                cell: (item: IAlertEmergency) => {
                    return <p
                        className={'text-center'}>{getResultName(item?.alert?.result)}</p>
                }
            }
        ]
        return result
    }, [])

    return <>
        <div className={`${className}`}>
            <div className={'flex py-[15px] px-[20px] items-center justify-end gap-[20px]'}>
                <Tooltip
                    render={<div className={'flex items-center font-medium text-main_b gap-[6px]'} title={'엑셀 다운로드'}>
                        <span>{t('notification.sos.excel')}</span>
                        <ExcelDownloadIcon />
                    </div>}>
                    <p className={'font-bold'}>{t('notification.sos.excelModal.title')}</p>
                    <div className={'flex items-center justify-centers gap-[10px] mt-[10px] whitespace-nowrap'}>
                        <Input inputType={'number'} className={'max-w-[60px] '}
                               onChanged={text => setYear(text)} />
                        {t('notification.sos.excelModal.year')} <Input
                        className={'max-w-[60px]'}
                        onChanged={text => setMonth(text)} />{t('notification.sos.excelModal.month')}
                        <SolidButton text={t('notification.sos.excelModal.download')} className={'ml-[20px]'}
                                     disabled={isEmpty(year) || isEmpty(month)}
                                     onClick={async () => {
                                         const response = await fetch(createUrl(`${API_ENDPOINT}/alert/emergency/excel`, {
                                             year: year,
                                             month: month
                                         }), {
                                             headers: {
                                                 Authorization: `Bearer ${getCookie(KEY_ACCESS_TOKEN)}`
                                             }
                                         })
                                         const url = window.URL.createObjectURL(await response.blob())
                                         const a = document.createElement('a')
                                         a.style.display = 'none'
                                         a.href = url
                                         a.download = `클로멘탈_긴급출동요청_${year}_${month}.xlsx`
                                         document.body.appendChild(a)
                                         a.click()
                                         window.URL.revokeObjectURL(url)
                                     }} />
                    </div>

                </Tooltip>
                <SearchBar inputParams={{ placeholder: t('notification.sos.search.placeholder') }}
                           className={'max-w-[230px]'} onChanged={text => {
                    setSearch(text)
                }} />
            </div>
            <Table
                data={data?.data || []} columns={columns} extractKey={(item) => item?.id}
                renderEmpty={<div className={'flex items-center justify-center h-full min-h-[200px]'}><p
                    className={'text-center'}>{t('notification.sos.table.empty')}</p></div>}
                className={'text-[14px]'}
                itemRowClassName={() => 'border-b border-solid border-gray2'}
                itemCommonClassName={() => 'py-[10px] px-[22px]'}
                onClickRow={(item: IAlertEmergency) => {
                    // 미처리 아이템도 선택할 수 있도록 변경
                    // if (equalString(item?.alert?.confirm, '담당자 배정') || equalString(item?.alert?.confirm, '미처리')) {
                    //     toast.error(`상태가 '처리' 인 아이템만 상세 정보를 보실 수 있습니다.`)
                    //     return
                    // }

                    setDetailModalId(item?.id)
                    detailModalRef.current?.show()
                }}
            />
            {(data?.pagination?.totalPages || 0) > 0 && <Pagination
                className={'mt-[25px]'}
                current_page={data?.pagination?.currentPage}
                page_size={PAGE_SIZE}
                total_page={data?.pagination?.totalPages}
                fetchPage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                }} />}
        </div>
        <EmergencyAlertModal ref={detailModalRef} defaultId={detailModalId} refetchAlerts={refetch} />
    </>
}

export default NotificationEmergencyContainer
import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { createUrl } from '@/util/url.ts'
import { MINUTE } from '@/constant/time.ts'

export const apiGetBioAlerts = async () => {
    return await apiGet<IResGetEmergencyAlerts>(createUrl(`/alert/bio/today`, {}))
}

const useQueryGetBioAlerts = () => {
    return useQuery({
        queryKey: QueryCacheKeys.notification.getAlertBioToday(),
        refetchInterval: MINUTE,
        queryFn: async () => {
            const { data } = await apiGetBioAlerts()
            return data
        }
    })
}

export default useQueryGetBioAlerts

export interface IEmergencyAlertSimple {
    id: number;
    createdAt: string;
}

export interface IResGetEmergencyAlerts {
    data: IEmergencyAlertSimple[];
}
import { isEmpty } from '@/util/strings.ts'

export function calculateAge(dateStr?: string) {
    if (!dateStr || isEmpty(dateStr))
        return

    var today = new Date()
    var birthDate = new Date(dateStr)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}
import React, { createContext, useImperativeHandle, useRef, useState } from 'react'
import BaseModal from '@/component/modal/BaseModal.tsx'
import useQueryGetAlertEmergencyLive from '@/api/notification/getLiveEmergencyAlert.ts'
import EmergencyAlertItem from '@/pages/notification/emergency/EmergencyAlertItem.tsx'
import EmergencyAlertDetail from '@/pages/notification/emergency/EmergencyAlertDetail.tsx'
import { isEmptyArray, isNotEmptyArray } from '@/util/array.ts'
import { useTranslation } from 'react-i18next'


export interface IProps {
    className?: string
    refetchAlerts?: () => void
    defaultId?: number
}

export type IEmergencyLiveAlertContextProps = {
    id?: number
    setId: (id: number) => void
    refetchList: () => void
    refetchAndResetSelection: () => void
}

export const EmergencyLiveAlertContext = createContext<IEmergencyLiveAlertContextProps>({
    id: undefined,
    setId: () => {
    },
    refetchList: () => {
    },
    refetchAndResetSelection: () => {
    }
})

const EmergencyAlertModal = ({ defaultId, refetchAlerts }: IProps, ref: any) => {
    const { t } = useTranslation()

    const modalRef = useRef<any>()
    const [id, setId] = useState<number>()
    const { data: alerts, refetch: refetchList } = useQueryGetAlertEmergencyLive(true)

    const refetchAndResetSelection = async () => {
        const { data: resAlerts } = await refetchList()
        setId(undefined)

        const newAlerts = resAlerts?.emergencies
        if (isEmptyArray(newAlerts)) {
            modalRef.current.close()
            return
        }

        const myAlerts = newAlerts?.filter(item => item?.alert?.isMyTask == true)
        if (myAlerts && isNotEmptyArray(myAlerts)) {
            setId(myAlerts[0].id)
            return
        }

        const notMyAlerts = newAlerts?.filter(item => !item?.alert?.taskManagerAt)
        if (notMyAlerts && isNotEmptyArray(notMyAlerts)) {
            setId(notMyAlerts[0].id)
            return
        }
    }

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        }
    }))

    return (
        <EmergencyLiveAlertContext.Provider value={{ id, setId, refetchList, refetchAndResetSelection }}>
            <BaseModal
                ref={modalRef}
                width={'1200'}
                onOpenCallback={() => {
                    if (id) return
                    if (defaultId) {
                        setId(defaultId)
                    } else if ((alerts?.emergencies?.length || 0) > 0) {
                        setId(alerts?.emergencies[0]!.id)
                    }
                }}
                onCloseCallback={() => {
                    setId(undefined)
                    refetchAlerts && refetchAlerts()
                }}
                showCloseButton={false}>

                <div className={'p-[23px] min-h-[670px]'}>
                    <div className={'grid grid-cols-[324px_820px] gap-[10px]'}>
                        {/* Live 목록 */}
                        <div className={'flex flex-col p-[10px] justify-start items-start overflow-y-auto h-full max-h-[600px]'}>
                            <div
                                className={'text-[20px] bg-sub_r text-white px-[15px] py-[5px] rounded-[5px]'}>
                                {t('notification.sosModal.title')}
                            </div>
                            <div className={'mt-[10px] flex flex-col gap-[10px] w-full h-full overflow-y-auto'}>
                                {Number(alerts?.emergencies?.length) > 0 ? alerts?.emergencies?.map(item =>
                                        <EmergencyAlertItem
                                            key={item?.id} {...item} />) :
                                    <div className={'grow flex items-center justify-center'}>
                                        <p>{t('notification.sosModal.empty')}</p>
                                    </div>}
                            </div>
                        </div>

                        {/* 알림 상세 내용 */}
                        <EmergencyAlertDetail />
                    </div>
                </div>
            </BaseModal>
        </EmergencyLiveAlertContext.Provider>

    )
}

export default React.forwardRef(EmergencyAlertModal)

import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { DISABLE_ALERTS_LIVE_FETCHING } from '@/constant/constant.ts'

export const apiGetEmergencyLiveAlert = async () => {
    return await apiGet<IResGetEmergencyLive>(`/alert/emergency/live`)
}

const useQueryGetUrgentNotification = (enabled = false) => {
    return useQuery({
        enabled: enabled && !DISABLE_ALERTS_LIVE_FETCHING,
        queryKey: QueryCacheKeys.notification.getEmergencyAlertLive(),
        refetchInterval: 1000,
        queryFn: async () => {
            const { data } = await apiGetEmergencyLiveAlert()
            return data
        }
    })
}

export default useQueryGetUrgentNotification

export interface IResGetEmergencyLive {
    emergencies: IAlertEmergencyLiveItem[];
}

export interface IEmergenciesProtectee {
    id: number;
    name: string;
}

export interface IEmergenciesAlert {
    createdAt: string;
    taskManagerAt: string;
    isMyTask: boolean;
}

export interface IAlertEmergencyLiveItem {
    id: number;
    protectee: IEmergenciesProtectee;
    alert: IEmergenciesAlert;
}

import React, { useImperativeHandle, useRef } from 'react'
import BaseModal from '@/component/modal/BaseModal.tsx'
import SolidButton from '@/component/button/SolidButton.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

const SalesModal = ({}: IProps, ref: any) => {
    const { t } = useTranslation()
    const modalRef = useRef<any>()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        }
    }))


    return (
        <BaseModal
            ref={modalRef}
            width={'400'}
            showCloseButton={false}>
            <div className={'flex flex-col justify-center items-center pt-[45px] pb-[30px]'}>
                <p className={'text-[16px] font-bold'}>{t('home.salesModal.title')}</p>

                <p className={'mt-[25px] text-[20px] font-bold'}>{t('home.salesModal.desc.phone')}</p>
                <p className={'text-[20px] font-bold'}>{t('home.salesModal.desc.email')}</p>

                <SolidButton className={'mt-[40px] min-w-[180px] rounded-[10px]'} text={t('home.salesModal.confirm')}
                             onClick={() => modalRef.current?.close()} />
            </div>
        </BaseModal>
    )
}

export default React.forwardRef(SalesModal)

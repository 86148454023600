import React from 'react'
import DolbomDreamLogo from '@/assets/img_logo_dolbomdream.png'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

const Footer: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()

    return <div
        className={`${className} px-[70px] md:px-[35px] py-[56px] md:py-[30px] bg-gray1`}>
        <a href={'/guide'} className={'font-medium py-[5px] cursor-pointer text-[14px]'}>{t('footer.manual')}</a>
        <div className={'gap-[19px] flex md:flex-col justify-between mt-[50px]'}>
            <div className={'text-[14px]'}>
                <p>{t('footer.companyName')} | {t('footer.ceoName')}</p>
                <p>{t('footer.phone')} | {t('footer.fax')}</p>
                <p>{t('footer.companyNo')}</p>
                <p>{t('footer.address')}</p>
                <p>{t('footer.email')}</p>
            </div>
            <div className={'flex items-end'}>
                <img src={DolbomDreamLogo} />
            </div>
        </div>

    </div>
}

export default Footer
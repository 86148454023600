import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import DefaultMap from '@/pages/notification/bio/DefaultMap.tsx'
import { format } from 'date-fns'
import ContactBadge from '@/component/badge/ContactBadge.tsx'
import { equalString, isEmpty } from '@/util/strings.ts'
import Input from '@/component/input/Input.tsx'
import Select, { ISelectOption } from '@/component/input/Select.tsx'
import BorderButton from '@/component/button/BorderButton.tsx'
import SolidButton from '@/component/button/SolidButton.tsx'
import useQueryGetUserDetail from '@/api/auth/getUser.ts'
import useQueryGetMe from '@/api/auth/getMe.ts'
import useQueryGetAlertEmergencyDetail from '@/api/notification/getAlertEmergencyDetail.ts'
import { EmergencyLiveAlertContext } from '@/pages/notification/emergency/EmergencyAlertModal.tsx'
import { EmergencyAlertConfirmStatus } from '@/api/notification/getNotificationSos.ts'
import {
    apiPatchEmergencyAlertAssignPerson,
    apiPatchEmergencyAlertProcess
} from '@/api/notification/patchEmergencyAlert.ts'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
}

const EmergencyAlertDetail: React.FC<IProps> = ({ className }) => {
    const { t } = useTranslation()

    const { id, refetchList, refetchAndResetSelection } = useContext(EmergencyLiveAlertContext)
    const { data, refetch: refetchDetail } = useQueryGetAlertEmergencyDetail(id)
    const { data: user } = useQueryGetUserDetail(data?.protectee?.id)
    const { data: me } = useQueryGetMe()
    const [isExpandedMap, setExpandedMap] = useState(false)

    const actionTypes: ISelectOption[] = useMemo(
        () => [
            {
                id: '기타',
                text: t('notification.sosModal.type.etc'),
                value: '기타'
            },
            {
                id: '119출동',
                text: t('notification.sosModal.type.119'),
                value: '119출동'
            },
            {
                id: '오작동',
                text: t('notification.sosModal.type.malfunction'),
                value: '오작동'

            }
        ],
        []
    )

    const [manager, setManager] = useState('')
    const [actionDetail, setActionDetail] = useState('')
    const [actionTypeId, setActionTypeId] = useState('기타')

    const onClickAssign = async () => {
        if (!id) return

        const { code } = await apiPatchEmergencyAlertAssignPerson(id, { manager_name: manager })
        if (code == 200) {
            await refetchDetail()
            refetchList()
        }
    }

    const getGenderName = useCallback((value: string) => {
        switch (value) {
            case '남':
                return t('notification.sosModal.gender.male')
            case '여':
                return t('notification.sosModal.gender.female')
            default:
                return value
        }
    }, [t])

    const onClickProcessAction = async () => {
        if (!id) return
        if (!actionTypeId) return

        const { code } = await apiPatchEmergencyAlertProcess(id, {
            task_result: actionTypeId,
            task_content: actionDetail
        })
        if (code == 200) {
            await refetchDetail()
            refetchAndResetSelection()
        }
    }

    useEffect(() => {
        setActionTypeId(data?.alert?.result || '기타')
        setActionDetail(data?.alert?.content || '')
        setManager(me?.name || '')
    }, [data, me])

    const canEditContent = useMemo(() => {
        const confirmStatus = data?.alert?.confirm

        switch (confirmStatus) {
            case EmergencyAlertConfirmStatus.DONE:
                return true
            case EmergencyAlertConfirmStatus.ASSIGN:
                return !!data?.alert?.isMyTask
            case  EmergencyAlertConfirmStatus.WAIT:
            default:
                return false
        }
    }, [data])


    return <div className={`${className}`}>
        <div
            className={'flex flex-col border border-solid border-transparent border-l-gray2 py-[20px] px-[5px]'}>
            <div
                className={'grid grid-cols-2 gap-[10px]'}>
                <div
                    className={'flex flex-col justify-center gap-[10px] p-[12px]'}>
                    <div
                        className={'bg-sub_b px-[15px] py-[8px] border border-solid border-main_b rounded-[5px] flex justify-between text-[20px] font-medium text-main_b'}>
                        <span>{data?.protectee?.name}</span>
                        <span>{data?.protectee?.contact}</span>
                    </div>
                    <div
                        className={'relative flex flex-col bg-gray1 px-[15px] py-[8px] border border-solid border-gray2 rounded-[5px] gap-[14px] cursor-pointer'}
                        onClick={() => setExpandedMap(!isExpandedMap)}>
                        <div className={'flex justify-between items-center'}>
                            <span>{data?.alert?.location?.address}</span>
                            <img src="/ic_map_marker.png" />
                        </div>
                        {isExpandedMap && data?.alert?.location && <DefaultMap
                            className={'min-h-[188px]'}
                            latitude={data?.alert?.location?.latitude}
                            longitude={data?.alert?.location?.longitude} />}
                    </div>
                    <p>{t('notification.sosModal.address')} : {user?.address ? user?.address : t('common.empty')}</p>
                    <p>{getGenderName(data?.protectee?.gender || '')} / {data?.protectee?.birthDate && format(data?.protectee?.birthDate, 'yy-MM-dd')}</p>

                    <p className={'text-left text-[14px] font-bold'}>{t('notification.sosModal.contact.title')}</p>
                    <div className={'grid grid-cols-2 gap-[10px]'}>
                        <div className={'flex gap-[10px]'}>
                            <ContactBadge>{t('notification.sosModal.contact.me')}</ContactBadge>
                            <p>{data?.protectee?.contact ? data?.protectee?.contact : t('common.empty')}</p>
                        </div>
                        <div className={'flex gap-[10px]'}>
                            <ContactBadge>{t('notification.sosModal.contact.protector')}</ContactBadge>
                            <p>{data?.protector?.contact ? data?.protector?.contact : t('common.empty')}</p>
                        </div>
                        <div className={'flex gap-[10px]'}>
                            <ContactBadge>{t('notification.sosModal.contact.acquaintance')}</ContactBadge>
                            <p>{data?.protector?.secondaryContact ? data?.protector?.secondaryContact : t('common.empty')}</p>
                        </div>
                        <div className={'flex gap-[10px]'}>
                            <ContactBadge>{t('notification.sosModal.contact.admin')}</ContactBadge>
                            <p>{data?.protector?.tertiaryContact ? data?.protector?.tertiaryContact : t('common.empty')}</p>
                        </div>
                    </div>

                    <p className={'text-[14px] font-bold mt-[10px]'}>{t('notification.sosModal.memo.title')}</p>
                    <div className={'p-[12px] bg-sub_b min-h-[158px]'}>
                        {data?.protectee?.memo}

                    </div>
                </div>
                <div className={'flex flex-col p-[12px] gap-[10px]'}>
                    {!equalString(data?.alert?.confirm, '미처리') &&
                        <div className={'flex gap-[13px] items-center'}>
                            <p className={'flex-none'}>{t('notification.sosModal.manager.title')}</p>
                            <Input disabled className={'w-full'} value={data?.alert?.managerName || ''} />
                        </div>}
                    <div className={'flex gap-[13px] items-center'}>
                        <p className={'flex-none'}>{t('notification.sosModal.type.title')}</p>
                        <Select className={'w-full'}
                                value={actionTypes?.find(item => item.id == actionTypeId)}
                                options={actionTypes}
                                disabled={!canEditContent}
                                onChanged={option =>
                                    setActionTypeId(option?.id)
                                } />
                    </div>
                    <div
                        className={`flex flex-col p-[12px] border border-solid border-gray2 h-full text-[14px] ${!canEditContent && 'bg-[#f1f1f1]'}`}>
                        <p>{t('notification.sosModal.process.title')} : </p>
                        <textarea className={`border-none w-full h-full resize-none`}
                                  disabled={!canEditContent}
                                  value={actionDetail}
                                  onChange={e => setActionDetail(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={'mt-[15.5px]'}>
                {/* 하단 버튼들 */}
                {data?.alert?.confirm == EmergencyAlertConfirmStatus.WAIT && <>
                    <div className={'flex gap-[20px] justify-center'}>
                        <input
                            className={'min-w-[370px] py-[13.5px] px-[15px] border border-solid border-sub_r'}
                            placeholder={t('notification.sosModal.manager.input.placeholder')}
                            value={manager}
                            onChange={e => {
                                const text = e.target.value
                                setManager(text)
                            }} />
                        <BorderButton text={t('notification.sosModal.manager.btn.title')}
                                      className={'text-sub_r border-sub_r min-w-[200px]'}
                                      onClick={onClickAssign} />
                    </div>
                </>}
                {data?.alert?.confirm == EmergencyAlertConfirmStatus.ASSIGN && data?.alert?.isMyTask && <>
                    <div className={'flex gap-[20px] justify-center'}>
                        <SolidButton text={t('notification.sosModal.submit.confirm')} className={'min-w-[200px]'}
                                     disabled={isEmpty(actionDetail) || isEmpty(actionTypeId) || !canEditContent}
                                     onClick={onClickProcessAction} />
                    </div>
                </>}
                {data?.alert?.confirm == EmergencyAlertConfirmStatus.ASSIGN && !data?.alert?.isMyTask &&
                    <div className={'flex gap-[20px] justify-center'}>
                        <button className={'min-w-[200px] bg-sub_r min-h-[50px] text-[16px] px-[20px] text-white'}
                                disabled>{t('notification.sosModal.manager.assign')}
                        </button>
                    </div>}
                {data?.alert?.confirm == EmergencyAlertConfirmStatus.DONE && <>
                    <div className={'flex gap-[8px] justify-center'}>
                        <SolidButton text={t('notification.sosModal.submit.save')} className={'min-w-[200px]'}
                                     onClick={onClickProcessAction} />
                    </div>
                </>}
            </div>
        </div>
    </div>
}

export default EmergencyAlertDetail
import { apiGet } from '../api.ts'
import { useQuery } from '@tanstack/react-query'
import { QueryCacheKeys } from '@/api/queryKeys.ts'
import { IAlertEmergency } from '@/api/notification/getNotificationSos.ts'

export const apiGetAlertEmergencyDetail = async (id?: number) => {
    return await apiGet<IAlertEmergency>(`/alert/emergency/${id}`)
}

const useQueryGetAlertEmergencyDetail = (id?: number) => {
    return useQuery({
        queryKey: QueryCacheKeys.notification.getAlertEmergencyDetail(id),
        enabled: (id || -1) >= 0,
        refetchInterval: 1000,
        queryFn: async () => {
            const { data } = await apiGetAlertEmergencyDetail(id)
            return data
        }
    })
}

export default useQueryGetAlertEmergencyDetail

